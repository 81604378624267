import React, { useEffect, useState } from 'react'
import { Input, Button } from '@cmd/library'
import { Link } from 'react-router-dom'

import Select from '../../components/Form/Select'
import { withContext } from '../../context/withContext'
import { httpClient } from '../../utils/httpClient';

import './Contact.css'

const Contact = ({ context: { loanPayload, setLoanPayload } }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [cities, setCities] = useState([]);

  // TODO: add request OTP

  const fetchCities = async () => {
    const cityResult = await httpClient.get('/city');

    setCities(cityResult.data.map((city) => ({ value: city.id, label: city.name })));
  };

  useEffect(() => {
    fetchCities();
  }, []);

  const validate = () => {
    if (loanPayload.city_id === '' || loanPayload.phone_number === '')  return false;

    if (loanPayload.phone_number.length < 9 || loanPayload.phone_number.length > 13) {
      return false;
    }

    if (errorMessage) return false;

    return true;
  }

  const validatePhoneNumber = (e) => {
    e.preventDefault();
    const phone_number = e.target.value;

    setLoanPayload({ phone_number });

    if (
      (phone_number[0] && phone_number[0] !== '0')
      ||
      (phone_number[1] && phone_number[1] !== '8')
    ) {
      setErrorMessage('Mohon isi nomor telpon dengan format 08xxx');
    } else {
      if (errorMessage) setErrorMessage(null);
    }
  }

  const requestOTP = () => {
    httpClient.post('/get_otp', { phone_number: loanPayload.phone_number });
  }

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="mb--2">
            <div>
              <Select
                placeholder="Pilih area tempat tinggal"
                value={loanPayload.city_id.toString()}
                options={cities}
                onChange={(selectValue) => setLoanPayload({ city_id: Number(selectValue) })} />
            </div>
            <div>
              <p className="text--size-12">
                Mohon maaf jika pilihan area tempat tinggal tidak tersedia, daerah Anda belum termasuk dalam area layanan kami.
              </p>
            </div>
          </div>
          <div className="mb--2">
            <Input type="number" placeholder="No Telepon (08xx)" value={loanPayload.phone_number} onChange={validatePhoneNumber} />
            { errorMessage && (<small style={{ color: 'red' }}>{errorMessage}</small>) }
          </div>
          <div className="fx">
            <div className="mr--2">
              <Link to="/pinjaman/otp">
                <Button
                  disabled={!validate()}
                  onClick={requestOTP}
                  text="Lanjut" />
              </Link>
            </div>
            <div>
              <Link to="/pinjaman"><Button text="Batal" className="btn--accent" /></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withContext(Contact)
