import React from "react";
import { Accordion } from "@cmd/library";

const Permata = () => {
  return (
    <div className="paylease-accordion-container">
      <div className="mb--1">
        <Accordion title="ATM Permata">
          <ol type="1">
            <li>Masukkan kartu ATM Permata anda</li>
            <li>Masukkan PIN</li>
            <li>Pilih menu "Transaksi Lainnya"</li>
            <li>Pilih menu "Pembayaran"</li>
            <li>Pilih menu "Pembayaran Lainnya"</li>
            <li>Pilih menu "Virtual Account"</li>
            <li>Masukkan Nomor Virtual Account <b>7293988549175775 (contoh)</b></li>
            <li>Lalu pilih rekening debet yang akan digunakan</li>
            <li>Konfirmasi detail transaksi anda</li>
            <li>Transaksi anda berhasil</li>
            <li>Setelah transaksi anda selesai, invoice ini akan dipudate secara otomatis. Proses ini  mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Internet Banking Permata">
          <ol type="1">
            <li>Buka situs <a href="https://new.permatanet.com" target="_blank">https://new.permatanet.com</a></li>
            <li>Masukkan User ID dan Password</li>
            <li>Pilih "Pembayaran Tagihan"</li>
            <li>Pilih "Virtual Account"</li>
            <li>Masukk Nomor Virtual Account <b>7293988549175775 (contoh)</b></li>
            <li>Periksa kembali detail pembayaran anda</li>
            <li>Masukkan otentikasi transaksi/token</li>
            <li>Transaksi Anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan dipudate secara otomatis. Proses ini  mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Mobile Banking Permata">
          <ol type="1">
            <li>Buka aplikasi PermataMobile Internet</li>
            <li>Masukkan User ID dan Password</li>
            <li>Pilih "Pembayaran Tagihan"</li>
            <li>Pilih "Virtual Account"</li>
            <li>Masukk Nomor Virtual Account <b>7293988549175775 (contoh)</b></li>
            <li>Masukkan otentikasi transaksi/token</li>
            <li>Transaksi Anda telah berhasil</li>
            <li>Setelah transaksi anda selesai, invoice ini akan dipudate secara otomatis. Proses ini  mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
    </div>
  )
}

export default Permata;
