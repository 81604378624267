import React, { Component } from 'react';
import momentTz from 'moment-timezone';
import moment from 'moment';
import 'moment/locale/id';
import { Button, Wizard, Input, Checkbox } from '@cmd/library';
import { rupiah, validateEmail, validatePhone } from '@cmd/library/lib/utils/function';
import { withRouter } from "react-router-dom";

import Select from '../../components/Form/Select'
import { httpClient } from '../../utils/httpClient';
import { withContext } from '../../context/withContext';
import { faSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

moment.locale('id');
momentTz.defineLocale('id', moment.localeData()._config);
momentTz.tz.setDefault('Asia/Jakarta');

const revertBackAmount = (amount) => {
  return Number(amount.toString().split(".").join(''))
}

const defaultState = {
  contractId: '',
  // isSyariah: false,
  voucher: '',
  voucherAmount: null,
  voucherApplied: false,
  paymentMethod: '',
  bank: '',
  isPaymentCompleted: false,
  error: '',
  errorAmount: '',
  errorVoucher: null,
  invoice: {},
  steps: [
    {
      text: 'No Tagihan',
      completed: false
    },
    {
      text: 'Detail Tagihan',
      completed: false
    },
    {
      text: 'Pilih Metode',
      completed: false
    },
    {
      text: 'Pembayaran',
      completed: false
    }
  ],
  trxId: '',
  isDetailInvoiceOpen: false,
  paymentMethodOptions: [],
  bankOptions: [],
  payment: {},
  requiredPaymentMethod: false,
  requiredPaymentChannel: false,
  notificationAddress: '',
  penaltyAmount: 0,
  loading: false,
  isVoucherCodeEnabled: false
};

class PayLease extends Component {
  constructor() {
    super();

    this.state = defaultState;
    this.changePage = this.changePage.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const currStep = this.props.context.payleaseWizardStep
    const { steps } = this.state
    if (prevProps.context.payleaseWizardStep !== currStep) {
      const newSteps = []
      for (let i = 0; i < steps.length; i++) {
        const newStep = steps[i]
        if (i <= (currStep - 1)) {
          newStep.completed = true
        } else {
          newStep.completed = false
        }
        newSteps.push(newStep)
      }
      this.setState({
        steps: newSteps
      })
    }
  }

  componentDidMount() {
    const currStep = this.props.context.payleaseWizardStep
    const { steps } = this.state
    if (currStep === 1) {
      const newSteps = []
      for (let i = 0; i < steps.length; i++) {
        const newStep = steps[i]
        if (i <= (currStep - 1)) {
          newStep.completed = true
        } else {
          newStep.completed = false
        }
        newSteps.push(newStep)
      }
      this.setState({
        steps: newSteps
      })
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  onContractIdChanged = (e) => {
    this.setState({
      contractId: e.target.value
    })
  }

  onVoucherInput = (e) => {
    this.setState({ voucher: e.target.value });
  }

  onVoucherApply = () => {
    const { voucher } = this.state;
    if (voucher === '') {
      this.setState({ errorVoucher: 'Mohon masukkan kode voucher yang sesuai' });
      return;
    }

    return httpClient.get(`/promo/${voucher}`)
      .then(({ data }) => this.setState({ voucherAmount: data.amount, errorVoucher: false }))
      .catch(() => this.setState({ errorVoucher: 'Voucher tidak valid' }))
      .finally(() => this.setState({ voucherApplied: true }))
  };

  onNotificationCheck = (e) => {
    this.setState({
      isNotificationChecked: e.target.checked
    })
  }

  changePage = (path) => {
    this.props.history.push(path);
  }

  nextStep = async () => {
    const {
      steps,
      invoice,
      notificationAddress,
      penaltyAmount
    } = this.state;

    const { payleaseWizardStep } = this.props.context

    if (payleaseWizardStep + 1 > steps.length) return;

    const defaultErrorMessage = 'Sedang terjadi kendala pada sistem. Mohon coba beberapa saat lagi';

    // TODO: move to util
    const errorHandler = (e) => {
      if (e.response) {
        const { data: { message } } = e.response;
        this.setState({ error: message, loading: false });
      } else {
        this.setState({ error: defaultErrorMessage, loading: false });
      }
    };

    switch(payleaseWizardStep) {
      case 1:
        this.setState({ loading: true });
        const lease = await httpClient.post("/lease/find", { id: this.state.contractId, is_syariah: this.state.isSyariah })
        .then(({ data }) => {
          this.setState({ invoice: { ...data }, error: false, loading: false });
          return true;
        })
        .catch((e) => {
          errorHandler(e);
          return false;
        });
        if (!lease) return;
        break;

      case 2: {
        const payload = { id_pel: invoice.id_pel };

        if (validateEmail(notificationAddress)) {
          payload.email = notificationAddress;
        } else if (
          ((notificationAddress[0] && notificationAddress[0] === '0')
          ||
          (notificationAddress[1] && notificationAddress[1] === '8'))
          && 
          notificationAddress.length >= 9
        ) {
          payload.phone_number = notificationAddress
        } else {
          this.setState({ error: "Mohon masukan email atau no telp yang valid." });
          return false;
        }

        if (parseInt(revertBackAmount(penaltyAmount)) > (Math.floor(parseInt(invoice.nilai_denda)/1000) + 1) * 1000) {
          this.setState({errorAmount: "Nilai input tidak boleh melebihi nilai denda"})
          return false;
        }

        this.setState({ loading: true });
        const paymentMethods = await Promise.all([
          httpClient.get(`/payment_method?id_pel=${invoice.id_pel}&penalty_amount=${revertBackAmount(penaltyAmount)}&email_phone=${notificationAddress}`),
          httpClient.post("/user/update", payload),
        ])
        .then(([{ data }]) => {
          const paymentMethodOptions = data.methods.map((method) => {
            return { label: method.label, value: method.type, list: method.list };
          });
          this.setState({ paymentMethodOptions, error: false, loading: false })
          return true
        })
        .catch((e) => {
          errorHandler(e);
          return false
        });
        if (!paymentMethods) return;
        break;
      }

      case 3:
        if (!this.state.paymentMethod) {
          this.setState({ requiredPaymentMethod: true });
          return;
        }

        if (!this.state.bank) {
          this.setState({ requiredPaymentChannel: true });
          return;
        }

        const bank_code = this.state.paymentMethodOptions
          .find(({ value }) => value === this.state.paymentMethod)
          .list
          .find(({ name }) => name === this.state.bank)
          .code;

        this.setState({ loading: true });
        const payment = await httpClient.post('/lease/pay', { id_pel: invoice.id_pel, bank_code, penalty_amount: revertBackAmount(penaltyAmount) })
        // const payment = await Promise.resolve({ data: { trx_id: 'A7198FDA65ED7BEB3ED7A5F9;' } })
        .then(({ data }) => {

          if (this.state.bank === 'Alfamart / Pos Indonesia') {
            window.location.replace(data.embed_kit_url);
          } else {
            this.setState({ loading: false });
            return this.changePage(`/pembayaran/${data.trx_id}`);
          }

        }).catch((e) => {
          console.log('error', e);
          errorHandler(e);
          return false;
        });

      default: break;
    }

    if (payleaseWizardStep === 3 && this.state.bank === 'Alfamart / Pos Indonesia') return;

    this.scrollToTop()
    this.props.context.setPayleaseWizardStep(payleaseWizardStep + 1)
  }

  renderWizardContent() {
    const {
      contractId,
      voucher,
      voucherAmount,
      voucherApplied,
      invoice,
      loading,
      paymentMethodOptions,
      paymentMethod,
      bankOptions,
      bank,
      error,
      errorAmount,
      errorVoucher,
      isDetailInvoiceOpen,
      notificationAddress,
      requiredPaymentMethod,
      requiredPaymentChannel,
      isVoucherCodeEnabled,
      penaltyAmount
    } = this.state

    const { payleaseWizardStep } = this.props.context

    const payButtonDisabled = isVoucherCodeEnabled && !voucherApplied ? true : false;

    const handleKeypress = e => {
      //it triggers by pressing the enter key
      if (e.key == 'Enter') {
        this.nextStep();
      }
    };

    const splitThousand = (number) => {
      if (!number && number !== 0) return "";
      return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };
  
    const convertPenaltyThousands = (e) => {
      if (!e) return;
      const formatted = (e.target.value.split(',').join('')).split('.').join('');
      if (isNaN(formatted)) return;
      const amount = splitThousand(formatted);
  
      this.setState({ penaltyAmount: amount })
    };

    switch (payleaseWizardStep) {
      case 1:
        return (
          <div>
            <Input type="text" placeholder="No Kontrak / No Polisi" value={contractId.trim()} onChange={this.onContractIdChanged} onKeyPress={handleKeypress}/>
            <small className='error-message'>{error}</small>
              <div className="d-flex flex-row">
                <div className="gap-3">
                  <Button type="submit" loading={loading} text="Cek Tagihan" disabled={contractId === ''} onClick={() => { this.nextStep(); }} />
                </div>
                {/* <div className="">
                  <Button type="submit" loading={loading} text="Cek Tagihan Syariah" disabled={contractId === ''} onClick={() => { this.setState({ isSyariah: true }, () => { this.nextStep()}); }} />
                </div> */}
                {/* <div className="col-md-8">
                  <button 
                  className='btn btn--green' 
                  type="submit" 
                  disabled={contractId === '' || loading}
                  onClick={() => { this.setState({ isSyariah: true }, () => { this.nextStep()}); }}>
                    {loading && ( <FontAwesomeIcon icon="circle-notch" spin style={{ marginRight: '5px' }} /> )}
                    Cek Tagihan Syariah</button>
                </div> */}
              </div>
          </div>
        )
      case 2:
        return (
          <div>
            <div>
              <div className="row row-gap">
                <div className="col-md-6">
                  <div>
                    <p className="text--size-12"><strong>Tagihan</strong></p>
                    <h2 className="text--weight-500">{rupiah(invoice.nilai_angsuran)}</h2>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <p className="text--size-12"><strong>Nama</strong></p>
                    <h2 className="text--weight-500">{invoice.nama_pel}</h2>
                  </div>
                </div>
              </div>
              <div className="row row-gap">
                <div className="col-md-6">
                  <div>
                    <p className="text--size-12"><strong>Jatuh Tempo</strong></p>
                    <h2 className="text--weight-500">{invoice.tanggal_jatuh_tempo}</h2>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <p className="text--size-12"><strong>No Pelanggan</strong></p>
                    <h2 className="text--weight-500">{invoice.id_pel}</h2>
                  </div>
                </div>
              </div>
              <div className={`toggle-section ${isDetailInvoiceOpen ? 'toggle-section--open' : ''}`}>
                <div className="row row-gap">
                  <div className="col-md-6">
                    <div>
                      <p className="text--size-12"><strong>No Handphone</strong></p>
                      <h2 className="text--weight-500">{invoice.handphone}</h2>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <p className="text--size-12"><strong>Tagihan ke / Tenor</strong></p>
                      <h2 className="text--weight-500">{invoice.angsuran_ke} / {invoice.term_kontrak}</h2>
                    </div>
                  </div>
                </div>
                <div className="row row-gap">
                  <div className="col-md-6">
                    <div>
                      <p className="text--size-12"><strong>Biaya Admin</strong></p>
                      <h2 className="text--weight-500">{rupiah(invoice.biaya_admin)}</h2>
                    </div>
                  </div>
                  
                  { invoice && invoice.tazir && invoice.nilai_denda !== '0' ? (
                    <div className="col-md-6">
                      <div>
                        <p className="text--size-12"><strong>Tawidh</strong></p>
                        <h2 className="text--weight-500">{rupiah(invoice.nilai_denda)}</h2>
                      </div>
                    </div>
                    ) : (
                      invoice && invoice.nilai_denda !== '0' && (
                        <div className="col-md-6">
                          <div>
                            <p className="text--size-12"><strong>Denda Telat</strong></p>
                            <h2 className="text--weight-500">{rupiah(invoice.nilai_denda)}</h2>
                          </div>
                        </div>
                      )
                    )}
                </div>
                { invoice && invoice.tazir ? (
                  <div className="row row-gap">
                    <div className="col-md-6">
                      <div>
                        <p className="text--size-12"><strong>Tazir</strong></p>
                        <h2 className="text--weight-500">{rupiah(invoice.tazir)}</h2>
                      </div>
                    </div>
                  </div>
                  ) : (<></>)
                }
              </div>
              <div className="row-gap">
                <p className="text--size-12 text--color-gold toggle-action" onClick={() => this.setState({ isDetailInvoiceOpen: !isDetailInvoiceOpen })}>
                  <strong>{ isDetailInvoiceOpen ?  'Tutup Detail Tagihan' : 'Lihat Detail Tagihan' }</strong>
                </p>
              </div>
            </div>
            <hr />
            { invoice && invoice.tazir && invoice.nilai_denda !== "0" ? (
              <div className="step1-container">
                <div className="row row-gap">
                  <div className="col-12 col-sm-12 col-md-10">
                    <div className="row-gap">
                      <p className="text--size-12">
                        <span className="text--weight-700">Total Tawidh saat ini: {rupiah(invoice.nilai_denda)}.</span><br/>
                        Isi nominal Tawidh yang dibayarkan.
                      </p>
                    </div>
                      <div>
                        <Input type="text" placeholder="0" value={penaltyAmount === 0 ? "" : penaltyAmount} onChange={(e) => convertPenaltyThousands(e)} onKeyPress={handleKeypress}/>
                        <small className="error-message">{errorAmount}</small>
                      </div>
                  </div>
                </div>
              </div>
            ) : ( 
              invoice && invoice.nilai_denda !== '0' && (
                <div className="step1-container">
                <div className="row row-gap">
                  <div className="col-12 col-sm-12 col-md-10">
                    <div className="row-gap">
                      <p className="text--size-12">
                        <span className="text--weight-700">Total Denda saat ini: {rupiah(invoice.nilai_denda)}.</span><br/>
                        Isi nominal denda yang dibayarkan.
                      </p>
                    </div>
                      <div>
                        <Input type="text" placeholder="0" value={penaltyAmount === 0 ? "" : penaltyAmount} onChange={(e) => convertPenaltyThousands(e)} onKeyPress={handleKeypress}/>
                        <small className="error-message">{errorAmount}</small>
                      </div>
                  </div>
                </div>
              </div>
              )
            )}
            <div className="step1-container">
              <div className="row row-gap">
                <div className="col-12 col-sm-12 col-md-10">
                  <div className="row-gap">
                    <p className="text--size-12">
                      <span className="text--weight-700">Terima bukti pembayaran melalui email/No. HP.</span><br/>
                      Gunakan Nomor HP untuk melakukan pembayaran dengan OVO
                    </p>
                  </div>
                    <div>
                      <Input type="text" placeholder="Email/No. HP (08XXXXXXX)" value={notificationAddress} onChange={(e) => this.setState({ notificationAddress: e.target.value.trim() })} onKeyPress={handleKeypress}/>
                      <small className="error-message">{error}</small>
                    </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="step1-container">
              <div className="row row-gap">
                <div className="col-md-5 col-6 col-sm-6">
                  <div className="row-gap">
                    <p className="text--size-12"><strong>Total Pembayaran</strong></p>
                    <h2 className="text--weight-500">{rupiah(parseInt(invoice.total_pembayaran) + parseInt(revertBackAmount(penaltyAmount)) + (parseInt(revertBackAmount(penaltyAmount)) === 0 ? 0 : parseInt(invoice.tazir) || 0))}</h2>
                  </div>
                  <div className="row-gap">
                    <Button text="Pilih Metode Pembayaran" onClick={this.nextStep} loading={loading} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      case 3:
        return (
          <div>
            <div className="row row-gap">
              <div className="col-12">
                <div>
                  <Select
                    id="paymentMethod"
                    placeholder="Payment Method"
                    required
                    value={paymentMethod}
                    options={paymentMethodOptions}
                    onChange={(selectValue) => {
                      this.setState({ paymentMethod: selectValue, requiredPaymentMethod: false });
                      const paymentOptionList = paymentMethodOptions
                        .find(({ value }) => value === selectValue)
                        .list
                        .map((opt) => ({ label: opt.name, value: opt.name }));

                      this.setState({ bankOptions: paymentOptionList });
                    }} />
                  { requiredPaymentMethod && (
                    <small className="error-message">Mohon pilih payment method yang tersedia</small>
                  ) }
                </div>
              </div>
            </div>
            <div className="row row-gap">
              <div className="col-12">
                <div>
                  <Select placeholder="Bank" value={bank} options={bankOptions} onChange={(selectValue) => this.setState({ bank: selectValue })}></Select>
                  { requiredPaymentChannel && (
                    <small className="error-message">Mohon pilih payment channel yang tersedia</small>
                  ) }
                </div>
              </div>
            </div>
            <hr className="row-gap" />
            <div className="row row-gap">
              <div className="col-12">
                <div>
                  <Checkbox id="voucherCode" onChange={(e) => this.setState({ isVoucherCodeEnabled: e.target.checked })}>
                    <p className="text--size-12">
                      <span className="text--weight-700">Saya punya voucher code</span><br/>
                    </p>
                  </Checkbox>
                </div>
                { isVoucherCodeEnabled && (
                  <div>
                    <Input
                      type="text"
                      placeholder="Voucher Code"
                      icon={
                        <span className="text--color-gold">Apply</span>
                      }
                      value={voucher}
                      onChange={this.onVoucherInput}
                      onClickIcon={this.onVoucherApply}
                    />
                    {
                      voucherAmount && (
                        <small><h4>Anda mendapatkan diskon sebesar {rupiah(voucherAmount)}</h4></small>
                      )
                    }
                    <small className="error-message">{errorVoucher}</small>
                  </div>
                ) }
              </div>
            </div>
            <div className="row-gap">
              <small className="error-message">{error}</small>
              <Button
                text="Bayar"
                disabled={payButtonDisabled}
                onClick={this.nextStep}
                loading={loading}
              />
            </div>
          </div>
        )
    }
  }

  render () {
    const { steps } = this.state

    return (
      <div className="row">
        <div className="col-lg-10 col-md-12">
          <Wizard steps={steps} />
          <div className="step1-container">
            {this.renderWizardContent()}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(withContext(PayLease));