import React from 'react'
import _ from 'lodash'

import { withContext } from '../context/withContext'

import CalculatorContent from './CalculatorContent'
import './Calculator.css'

const Calculator = ({ context }) => {
  function handlePopupClick (e) {
    if (e.target.classList.contains('popup')) {
      context.setShowCalculator(false)
    }
  }

  return (
    <div className={`popup ${context.showCalculator ? 'popup--show' : null}`} onClick={handlePopupClick}>
      <div className="popup-content">
        <CalculatorContent />
      </div>
    </div>
  )
}

export default withContext(Calculator)
