import React, { useEffect, useState } from 'react'
import { Upload, Input, Radio, Button } from '@cmd/library'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { validateEmail } from '@cmd/library/lib/utils/function';

import Select from '../../components/Form/Select'
import { withContext } from '../../context/withContext'
import { httpClient } from '../../utils/httpClient'

const PersonalInformation = ({ context, history }) => {
  const [errorMessages, setErrorMessages] = useState({});
  const [uploading, setUploading] = useState(false)
  const [options, setOptions] = useState({
    residency_status: [],
    residency_term: [],
    income_range: [],
    marital_status: [],
    work_type: [],
    work_experience: [],
  })

  const fetchOptions = async (field) => {
    const result = await httpClient.get(`/${field}`)

    return { [field]: result.data.map((val) => ({ value: val.id, label: val.name })) };
  }

  const validateNoKtp = (nomor) => {
    let pattern = /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/;
    return pattern.test(nomor);
  }

  const fetchAndSetOptions = async () => {
    const staticFields = await Promise.all([
      fetchOptions('residency_status'),
      fetchOptions('residency_term'),
      fetchOptions('income_range'),
      fetchOptions('marital_status'),
      fetchOptions('work_type'),
      fetchOptions('work_experience'),
    ]);

    const newOptions = staticFields.reduce((a, b) => Object.assign(a, b), {});

    setOptions(newOptions);

    const result = await httpClient.get(`/folder_name`)
    context.setFolderName(result.data)
  }

  useEffect(() => {
    fetchAndSetOptions()
  }, [])

  const yesOrNo = [
    {
      id: 'yes',
      value: 'yes',
      text: 'Yes'
    },
    {
      id: 'no',
      value: 'no',
      text: 'No'
    }
  ]

  const isEmployeeOption = [
    {
      id: 'employee_yes',
      value: 'yes',
      text: 'Karyawan'
    },
    {
      id: 'employee_no',
      value: 'no',
      text: 'Wiraswasta'
    }
  ]

  const totalChildOptions = [
    {
      value: '0',
      label: '0'
    },
    {
      value: '1',
      label: '1'
    },
    {
      value: '2',
      label: '2'
    },
    {
      value: '3',
      label: '3'
    },
    {
      value: '> 3',
      label: '> 3'
    }
  ]

  function validateBeforeInput () {
    if (context.loanPayload.ktp_image_url === '') return false;
    if (context.loanPayload.full_name === '') return false;
    if (context.loanPayload.date_of_birth === '') return false;
    if (!context.loanPayload.is_address_same_as_ktp && context.loanPayload.residency_address === '') return false;
    if (context.loanPayload.residency_status_id === '') return false;
    if (context.loanPayload.residency_term_id === '') return false;
    if (context.loanPayload.marital_status_id === '') return false;
    if (context.loanPayload.work_type_id === '') return false;
    if (context.loanPayload.work_experience_id === '') return false;
    if (context.loanPayload.income_range_id === '') return false;
    if (context.loanPayload.company_name === '') return false;
    if (context.loanPayload.company_address === '') return false;
    if (context.loanPayload.email === '') return false;
    if (context.loanPayload.ktp_number === '') return false;

    if (context.loanPayload.marital_status_id === '1') {
      if (context.loanPayload.partner_ktp_image_url === '') return false;
      if (context.loanPayload.total_child === '') return false;
    }

    const errors = Object.values(errorMessages).filter((val) => val);
    if (errors.length) return false;

    return true
  }

  function handleUpload (file, id, name) {
    if (file) {
      setUploading(true)
      const formData = new FormData()

      formData.append('image_name', id)
      formData.append('image', file)
      formData.append('folder', context.folderName)

      return httpClient.post('/upload', formData).then(({ data }) => {
        if (id === 'ktp_image') context.setLoanPayload({ ktp_number: data.nik });
        context.setLoanPayload({ [name]: data.url })
        setUploading(false)
      })
    }
  }

  function renderMariedUserData () {
    const marital = options.marital_status.find(({ value }) => value === Number(context.loanPayload.marital_status_id))

    if (marital && marital.label === 'Kawin') {
      return (
        <>
          <div className="row mb--1">
            <div className="col-md-8">
              <Upload
                onChange={(value) => handleUpload(value, 'partner_ktp', 'partner_ktp_image_url')}
                accept="image/*"
                placeholder={
                  <div className="text--center">
                    <img src={require('../../assets/img/camera-icon.svg')} alt="" className="mb--1"/>
                    <p className="text--size-12 text--color-gold mb--0"><strong>Foto KTP pasangan</strong></p>
                  </div>
                }
              />
            </div>
          </div>
          <div className="row mb--1">
            <div className="col">
              <p className="text--size-12"><strong>Jumlah Anak</strong></p>
              <Select
                placeholder="Jumlah Anak"
                value={context.loanPayload.total_child}
                options={totalChildOptions}
                onChange={(selectValue) => context.setLoanPayload({ total_child: selectValue })}
                icon={<FontAwesomeIcon icon="angle-down" />} />
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <div>
      <div className="row mb--1">
        <div className="col-md-8">
          <Upload
            onChange={(value) => handleUpload(value, 'ktp_image', 'ktp_image_url')}
            accept="image/*"
            placeholder={
              <div className="text--center">
                <img src={require('../../assets/img/camera-icon.svg')} alt="" className="mb--1"/>
                <p className="text--size-12 text--color-gold mb--0"><strong>Foto KTP pemohon</strong></p>
              </div>
            }
          />
        </div>
      </div>
      <div className="row mb--1">
        <div className="col-md-8">
          <Upload
            onChange={(value) => handleUpload(value, 'kk_image', 'kk_image_url')}
            accept="image/*"
            placeholder={
              <div className="text--center">
                <img src={require('../../assets/img/camera-icon.svg')} alt="" className="mb--1"/>
                <p className="text--size-12 text--color-gold mb--0"><strong>Foto KK pemohon</strong></p>
              </div>
            }
          />
        </div>
      </div>
      <div className="row mb--1">
        <div className="col">
          <Input
            type="email"
            placeholder="Alamat E-mail"
            value={context.loanPayload.email}
            onBlur={(e) => {
              const valid = validateEmail(e.target.value);

              if (valid) {
                setErrorMessages({ ...errorMessages, email: null })
              } else {
                setErrorMessages({ ...errorMessages, email: 'Mohon isi dengan email yang valid' });
              }
            }}
            onChange={(e) => context.setLoanPayload({ email: e.target.value }) }
          />
          { errorMessages.email && (<small style={{ color: 'red' }}>{errorMessages.email}</small>) }
        </div>
      </div>
      <div className="row mb--1">
        <div className="col">
          <Input
            type="text"
            placeholder="Nama Lengkap Sesuai KTP"
            value={context.loanPayload.full_name}
            onChange={(e) => context.setLoanPayload({ full_name: e.target.value }) }
          />
        </div>
      </div>
      <div className="row mb--1">
        <div className="col">
          <Input
            type="number"
            placeholder="Nomor KTP"
            value={context.loanPayload.ktp_number}
            onBlur={(e => {
              const valid = validateNoKtp(e.target.value);

              if (valid) {
                setErrorMessages({ ...errorMessages, ktp: null })
              } else {
                setErrorMessages({ ...errorMessages, ktp: 'Mohon isi dengan nomor ktp yang valid' });
              }
            })}
            onChange={(e) => context.setLoanPayload({ ktp_number: e.target.value }) }
          />
          { errorMessages.ktp && (<small style={{ color: 'red' }}>{errorMessages.ktp}</small>) }
        </div>
      </div>
      <div className="row mb--1">
        <div className="col">
          <p className="text--size-12"><strong>Tanggal Lahir</strong></p>
          <Input
            type="date"
            placeholder="Tanggal Lahir"
            value={context.loanPayload.date_of_birth}
            onChange={(e) => context.setLoanPayload({ date_of_birth: e.target.value })}
          />
        </div>
      </div>
      <div className="row mb--1">
        <div className="col">
          <div>
            <p className="text--size-12"><strong>Alamat Tempat Tinggal Sesuai KTP</strong></p>
            <Radio
              value={context.loanPayload.is_address_same_as_ktp}
              name="isAddressSameAsKtp"
              options={yesOrNo}
              onChange={(e) => context.setLoanPayload({ is_address_same_as_ktp: e === 'yes' ? true : false })} />
          </div>
        </div>
      </div>
      { !context.loanPayload.is_address_same_as_ktp && (
        <div className="row mb--1">
          <div className="col">
            <Input
              type="text"
              placeholder="Alamat Domisili"
              value={context.loanPayload.residency_address}
              onChange={(e) => context.setLoanPayload({ residency_address: e.target.value })}
            />
          </div>
        </div>
      )}
      <div className="row mb--1">
        <div className="col">
          <Select
            placeholder="Status Tempat Tinggal"
            value={context.loanPayload.residency_status_id}
            options={options.residency_status}
            onChange={(selectValue) => context.setLoanPayload({ residency_status_id: Number(selectValue) })}
            icon={<FontAwesomeIcon icon="angle-down" />} />
        </div>
      </div>
      <div className="row mb--1">
        <div className="col">
          <Select
            placeholder="Lama Menempati Tempat Tinggal"
            value={context.loanPayload.residency_term_id}
            options={options.residency_term}
            onChange={(selectValue) => context.setLoanPayload({ residency_term_id: Number(selectValue) })}
            icon={<FontAwesomeIcon icon="angle-down" />} />
        </div>
      </div>
      <div className="row mb--1">
        <div className="col">
          <Select
            placeholder="Status Perkawinan"
            value={context.loanPayload.marital_status_id}
            options={options.marital_status}
            onChange={(selectValue) => context.setLoanPayload({ marital_status_id: Number(selectValue) })}
            icon={<FontAwesomeIcon icon="angle-down" />} />
        </div>
      </div>
      { renderMariedUserData() }
      <div className="row mb--1">
        <div className="col">
          <div>
            <p className="text--size-12"><strong>Jenis Pekerjaan</strong></p>
            <Radio
              value={context.loanPayload.is_employee}
              name="isEmployee"
              options={isEmployeeOption}
              onChange={(e) => context.setLoanPayload({ is_employee: e === 'yes' ? true : false })} />
          </div>
        </div>
      </div>
      <div className="row mb--1">
        <div className="col">
          <Select
            placeholder="Pekerjaan"
            value={context.loanPayload.work_type_id}
            options={options.work_type}
            onChange={(selectValue) => context.setLoanPayload({ work_type_id: Number(selectValue) })}
            icon={<FontAwesomeIcon icon="angle-down" />} />
        </div>
      </div>
      <div className="row mb--1">
        <div className="col">
          <Select
            placeholder="Pendapatan Perbulan"
            value={context.loanPayload.income_range_id}
            options={options.income_range}
            onChange={(selectValue) => context.setLoanPayload({ income_range_id: Number(selectValue) })}
            icon={<FontAwesomeIcon icon="angle-down" />} />
            <div>
              <p className="text--size-12"><strong>Apabila menikah, pendapatan suami + pendapatan istri</strong></p>
            </div>
        </div>
      </div>
      <div className="row mb--1">
        <div className="col">
          <Select
            placeholder="Lama Bekerja"
            value={context.loanPayload.work_experience_id}
            options={options.work_experience}
            onChange={(selectValue) => context.setLoanPayload({ work_experience_id: Number(selectValue) })}
            icon={<FontAwesomeIcon icon="angle-down" />} />
        </div>
      </div>
      <div className="row mb--1">
        <div className="col">
          <Input
            type="text"
            placeholder="Nama Perusahaan"
            value={context.loanPayload.company_name}
            onChange={(e) => context.setLoanPayload({ company_name: e.target.value })}
          />
        </div>
      </div>
      <div className="row mb--1">
        <div className="col">
          <Input
            type="text"
            placeholder="Alamat Perusahaan"
            value={context.loanPayload.company_address}
            onChange={(e) => context.setLoanPayload({ company_address: e.target.value })}
          />
        </div>
      </div>
      <div className="fx">
        <div className="mr--2">
          <Link to="/pinjaman/guarantee">
            <Button
              text="Lanjut"
              disabled={uploading || !validateBeforeInput()} />
            <br/>
            {/* { errorMessage && (<small style={{ color: 'red' }}>{errorMessage}</small>) } */}
          </Link>
        </div>
        <div>
          <Button text="Batal" className="btn--accent" onClick={() => history.push('/pinjaman/contact')} />
        </div>
      </div>
    </div>
  )
}

export default withContext(PersonalInformation)
