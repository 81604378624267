import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { Wizard } from '@cmd/library';

const MainWizard = ({ location }) => {
  const [steps, setSteps] = useState([
    {
      text: 'Pinjaman',
      completed: false
    },
    {
      text: 'Kontak',
      completed: false
    },
    {
      text: 'OTP',
      completed: false
    },
    {
      text: 'Data Diri',
      completed: false
    },
    {
      text: 'Jaminan',
      completed: false
    }
  ])

  useEffect(() => {
    const pathname = location.pathname.replace(/\/$/, '')

    let newSteps = []
    if (pathname === '/pinjaman/guarantee') {
      newSteps = steps.map((step) => {
        step.completed = true
        return step
      })
    } else if (pathname === '/pinjaman/personal-information') {
      newSteps = steps.map((step, index) => {
        if (index > 3) {
          step.completed = false
          return step
        }
        step.completed = true
        return step
      })
    } else if (pathname === '/pinjaman/otp') {
      newSteps = steps.map((step, index) => {
        if (index > 2) {
          step.completed = false
          return step
        }
        step.completed = true
        return step
      })
    } else if (pathname === '/pinjaman/contact') {
      newSteps = steps.map((step, index) => {
        if (index > 1) {
          step.completed = false
          return step
        }
        step.completed = true
        return step
      })
    } else if (pathname === '/pinjaman') {
      newSteps = steps.map((step, index) => {
        if (index > 0) {
          step.completed = false
          return step
        }
        step.completed = true
        return step
      })
    }
    setSteps(newSteps)
  }, [location.pathname])

  return (
    <div>
      <Wizard steps={steps} />
    </div>
  )
}

export default withRouter(MainWizard)