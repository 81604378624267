import React from "react";
import { Accordion } from "@cmd/library";

const Bca = () => {
  return (
    <div className="paylease-accordion-container">
      <div className="mb--1">
        <Accordion title="ATM BCA">
          <ol type="1">
            <li>Masukkan Kartu ATM BCA</li>
            <li>Masukkan PIN</li>
            <li>Pilih menu "Transaksi Lainnya"</li>
            <li>Pilih menu "Transfer"</li>
            <li>Pilih menu "ke Rekening BCA Virtual Account"</li>
            <li>Masukkan Nomor Virtual Account Anda contoh: <b>700701598855309526</b>. Tekan "Benar" untuk melanjutkan</li>
            <li>Di halaman konfirmasi, pastikan detil pembayaran sudah sesuai seperti No VA, Nama, Perus/Produk dan Total Tagihan, tekan "Benar" untuk melanjutkan</li>
            <li>Tekan "Ya" jika sudah benar</li>
            <li>Transaksi Anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Internet Banking BCA">
          <ol type="1">
            <li>Lakukan log in pada aplikasi KlikBCA Individual <a href="https://ibank.klikbca.com" target="_blank">https://ibank.klikbca.com</a></li>
            <li>Masukkan User ID dan PIN</li>
            <li>Pilih "Transfer Dana", kemudian pilih "Transfer ke BCA Virtual Account"</li>
            <li>Masukkan Nomor Virtual Account contoh: <b>700701598855309526</b></li>
            <li>Pilih "Lanjutkan"</li>
            <li>Masukkan "RESPON KEYBCA APPLI 1" yang muncul pada Token BCA anda, kemudian tekan tombol "Kirim"</li>
            <li>Transaksi Anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Mobile Banking BCA">
          <ol type="1">
            <li>Buka aplikasi BCA Mobile</li>
            <li>Pilih menu "m-BCA", kemudian masukkan kode akses m-BCA</li>
            <li>Pilih "Transaction" lalu pilih "m-Transfer", kemudian pilih "BCA Virtual Account"</li>
            <li>Masukkan Nomor Virtual Account anda contoh: <b>700701598855309526</b>, kemudian tekan "OK"</li>
            <li>Tekan tombol "Kirim" yang berada di sudut kanan atas aplikasi untuk melakukan transfer</li>
            <li>Tekan "OK" untuk melanjutkan pembayaran</li>
            <li>Masukkan PIN Anda untuk meng-otorisasi transaksi</li>
            <li>Transaksi Anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
    </div>
  )
}

export default Bca;
