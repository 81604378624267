import React from 'react'

import './Success.css'

const Sucess = () => {
  return (
    <div>
      <div className="row mb--1">
        <div className="col">
          <img src={require('../../assets/img/success-illu.svg')} alt=""/>
        </div>
      </div>
      <div className="row mb--1">
        <div className="col">
          <p className="text--success">Pengajuan pinjaman Anda telah kami terima dan sedang kami proses. Kami akan menghubungi Anda maksimal 5 hari kerja kedepan.</p>
        </div>
      </div>
    </div>
  )
}

export default Sucess