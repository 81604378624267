import React from "react";
import { Accordion } from "@cmd/library";

const Bni = () => {
  return (
    <div className="paylease-accordion-container">
      <div className="mb--1">
        <Accordion title="ATM BNI">
          <ol type="1">
            <li>Masukkan kartu ATM Anda</li>
            <li>Pilih bahasa</li>
            <li>Pada PIN ATM anda</li>
            <li>Pilih "Menu Lainnya"</li>
            <li>Pilih "Transfer"</li>
            <li>Pilih jenis rekening yang akan anda gunakan (contoh: "Dari Rekening Tabungan")</li>
            <li>Pilih "Virtual Account Billing"</li>
            <li>Masukkan Nomor Virtual Account anda contoh: <b>88089885566202621</b></li>
            <li>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi</li>
            <li>Konfirmasi, apabila telah sesuai, lanjutkan transaksi</li>
            <li>Transaksi anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan dipudate secara otomatis. Proses ini  mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Internet Banking BNI">
          <ol type="1">
            <li>Buka situs <a href="https://ibank.bni.co.id" target="_blank">https://ibank.bni.co.id</a></li>
            <li>Masukkan USER ID dan PASSWORD</li>
            <li>Pilih menu "Transaksi"</li>
            <li>Pilih menu "Virtual Account Billing"</li>
            <li>Masukkan Nomor Virtual Account contoh: <b>8808988556620621</b></li>
            <li>Lalu pilih rekening debet yang akan digunakan. Kemudian tekan "Lanjut"</li>
            <li>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi</li>
            <li>Masukkan Kode Otentikasi Token</li>
            <li>Transaksi Anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Mobile Banking BNI">
          <ol type="1">
            <li>Akses BNI Mobile Banking melalui handphone</li>
            <li>Masukkan User ID dan Password</li>
            <li>Pilih menu "Transfer"</li>
            <li>Pilih menu "Virtual Account Billing", lalu pilih rekening debet</li>
            <li>Masukkan Nomor Virtual Account anda contoh: <b>8808988556620621</b> pada menu "Input Baru"</li>
            <li>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi</li>
            <li>Konfirmasi transaksi dan masukkan Password Transaksi</li>
            <li>Transaksi Anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
    </div>
  )
}

export default Bni;
