import React, { Component } from 'react'
import { Context } from './Context'

export default class ContextProvider extends Component {
  constructor () {
    super()

    this.state = {
      isBigMenuOpen: false,
      setBigMenuOpen: this.setBigMenuOpen,
      showCalculator: false,
      setShowCalculator: this.setShowCalculator,
      loanSimulation: {
        guarantee: localStorage.getItem('guarantee') || 'motor',
        loanAmount: localStorage.getItem('loan_amount') || 3000000,
        installmentPerMonth: localStorage.getItem('installmentPerMonth') || 0,
        installmentPeriod: localStorage.getItem('tenor') || 6
      },
      loanPayload: {
        // loan
        loan_amount: Number(localStorage.getItem('loan_amount')) || 3000000,
        tenor: Number(localStorage.getItem('tenor')) || 6,
        collateral_is_car: localStorage.getItem('collateral_is_car') || false,
        // contact
        city_id: Number(localStorage.getItem('city_id')) || '',
        phone_number: localStorage.getItem('phone_number') || '',
        // personal information
        email: localStorage.getItem('ktp_image_url') || '',
        ktp_image_url: localStorage.getItem('ktp_image_url') || '',
        full_name: localStorage.getItem('full_name') || '',
        ktp_number: localStorage.getItem('ktp_number') || '',
        date_of_birth: localStorage.getItem('date_of_birth') || '',
        is_address_same_as_ktp: localStorage.getItem('is_address_same_as_ktp') || false,
        residency_address: localStorage.getItem('residency_address') || '',
        residency_status_id: Number(localStorage.getItem('residency_status_id')) || '',
        residency_term_id: Number(localStorage.getItem('residency_term_id')) || '',
        marital_status_id: Number(localStorage.getItem('marital_status_id')) || '',
        partner_ktp_image_url: localStorage.getItem('partner_ktp_image_url') || '',
        total_child: localStorage.getItem('total_child') || '0',
        is_employee: localStorage.getItem('is_employee') || false,
        work_type_id: Number(localStorage.getItem('work_type_id')) || '',
        work_experience_id: Number(localStorage.getItem('work_experience_id')) || '',
        income_range_id: Number(localStorage.getItem('income_range_id')) || '',
        company_name: localStorage.getItem('company_name') || '',
        company_address: localStorage.getItem('company_address') || '',
        // Collaterall
        collateral_year: localStorage.getItem('collateral_year') || '',
        collateral_front_image_url: localStorage.getItem('collateral_front_image_url') || '',
        collateral_rear_image_url: localStorage.getItem('collateral_rear_image_url') || '',
        collateral_dashboard_image_url: localStorage.getItem('collateral_dashboard_image_url') || '',
        stnk_image_url: localStorage.getItem('stnk_image_url') || '',
      },
      folderName: localStorage.getItem('folderName') || '',
      setFolderName: (val) => {
        this.setState({ folderName: val })
      },
      setLoanSimulation: this.setLoanSimulation,
      setLoanPayload: this.setLoanPayload,
      payleaseWizardStep: 1,
      setPayleaseWizardStep: this.setPayleaseWizardStep
    }
  }

  setBigMenuOpen = (value) => {
    this.setState({ isBigMenuOpen: value })
  }

  setShowCalculator = (value) => {
    this.setState({ showCalculator: value })
  }

  setLoanSimulation = (value) => {
    const [key, val] = Object.entries(value)[0];
    this.setState({ loanSimulation: { ...this.state.loanSimulation, ...value } })
  }

  setLoanPayload = (value) => {
    const [key, val] = Object.entries(value)[0];
    this.setState({ loanPayload: { ...this.state.loanPayload, ...value } })
  }

  setPayleaseWizardStep = (value) => {
    this.setState({ payleaseWizardStep: value })
  }

  render () {
    return (
      <Context.Provider value={{
        context: {
          ...this.state
        }
      }}>
        { this.props.children }
      </Context.Provider>
    )
  }
}