import React from "react";
import { Sidebar } from "@cmd/library";

const PayleaseSidebar = () => {
  return (
    <Sidebar
    title="Bayar angsuran kredit kamu disini"
    description={<React.Fragment>
      <div className="sidebar-row">
        <div className="sidebar-row-icon">
          <img src={require("../../assets/img/icon-square.svg")} alt="" />
        </div>
        <div>
          <span className="sidebar-text-description">
            Berbagai macam pilihan pembayaran
          </span>
        </div>
      </div>
      <div className="sidebar-row">
        <div className="sidebar-row-icon">
          <img src={require("../../assets/img/icon-clock.svg")} alt="" />
        </div>
        <div>
          <span className="sidebar-text-description">
            Konfirmasi dalam waktu 5 menit
          </span>
        </div>
      </div>
      <div className="sidebar-row">
        <div className="sidebar-row-icon">
          <img src={require("../../assets/img/icon-email.png")} alt="" />
        </div>
        <div>
          <span className="sidebar-text-description">
            Terima Bukti Pembayaran di Email
          </span>
        </div>
      </div>
    </React.Fragment>}
    sidebarArt={<img src={require("../../assets/img/sidebar-art-paylease.png")} alt="" />}
  />
  )
}

export default PayleaseSidebar;