import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Sidebar } from '@cmd/library'
import { rupiah } from '@cmd/library/lib/utils/function'
import {
  Switch
} from 'react-router-dom'

import { withContext } from '../../context/withContext'
import DanatunaiWizard from '../../components/DanatunaiWizard'
import Footer from '../../components/Footer'
import Calculator from '../../components/Calculator'
import { RouteWithSubRoutes } from '../../App'

const Danatunai = ({ routes, location, context }) => {
  const [showCalculator, setShowCalculator] = useState(false)
  const [applicationSubmitted, setApplicationSubmitted] = useState(false)

  useEffect(() => {
    const pathname = location.pathname.replace(/\/$/, '')
    if (pathname === '/pinjaman') {
      setShowCalculator(false)
    } else if (pathname === '/pinjaman/loan-submitted') {
      setApplicationSubmitted(true)
    } else {
      setShowCalculator(true)
    }
  }, [location.pathname])

  return (
    <div className="main-page">
      <div className="container-fluid">
        <div className="row justify-content-end">
          <div className="col-md-4 sidebar-col">
            <div className={`sidebar-page-wrapper ${showCalculator ? 'spw--calculator' : ''}`}>
              <Sidebar
                title="Ajukan pinjaman online dengan cepat"
                description={
                  applicationSubmitted ? (
                    null
                  ) : (
                    showCalculator ? (
                      <React.Fragment>
                        <div className="sidebar-row">
                          <div>
                            <p><strong>Total Pinjaman</strong></p>
                            <h2 className="text--weight-500 mb--0">{ rupiah(context.loanSimulation.loanAmount) }</h2>
                          </div>
                        </div>
                        <div className="sidebar-row">
                          <div>
                            <p><strong>Cicilan Perbulan</strong></p>
                            <h2 className="text--weight-500 mb--0">{ rupiah(context.loanSimulation.installmentPerMonth) }</h2>
                          </div>
                        </div>
                        <div className="sidebar-row mb--2">
                          <div>
                            <p><strong>Lama Cicilan</strong></p>
                            <h2 className="text--weight-500 mb--0">{ context.loanSimulation.installmentPeriod } Bulan</h2>
                          </div>
                        </div>
                        <div className="sidebar-row mb--2">
                          <p className="text--size-12 text--color-gold mb--0 cursor-pointer" onClick={() => context.setShowCalculator(true)}>
                            <strong>Ubah Pinjaman</strong>
                          </p>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="sidebar-row">
                          <div className="sidebar-row-icon">
                            <img src={require('../../assets/img/icon-square.svg')} alt=""/>
                          </div>
                          <div>
                            <span className="sidebar-text-description">Syarat pengajuan yang mudah</span>
                          </div>
                        </div>
                        <div className="sidebar-row">
                          <div className="sidebar-row-icon">
                            <img src={require('../../assets/img/icon-pencairan.svg')} alt=""/>
                          </div>
                          <div>
                            <span className="sidebar-text-description">Nominal pencairan dana tinggi</span>
                          </div>
                        </div>
                        <div className="sidebar-row">
                          <div className="sidebar-row-icon">
                            <img src={require('../../assets/img/icon-clock.svg')} alt=""/>
                          </div>
                          <div>
                            <span className="sidebar-text-description">1 jam pencairan dana</span>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  )     
                }
                sidebarArt={
                  <img src={require('../../assets/img/sidebar-art.png')} alt=""/>
                }
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="main-page-wrapper">
                  <div className="main-page-container">
                    <div className="row">
                      <div className="col-lg-10 col-md-12">
                        <DanatunaiWizard />
                      </div>
                    </div>
                    <div className="row page-container">
                      <div className="col-lg-10 col-md-12">
                        <Switch>
                          {routes.map((route, i) => (
                            <RouteWithSubRoutes key={i} {...route} />
                          ))}
                        </Switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-footer-container">
              <Footer />
            </div>
          </div>
        </div>
      </div>
      { context.showCalculator && <Calculator /> }
    </div>
  )
}

export default withRouter(withContext(Danatunai))