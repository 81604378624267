import React from 'react'
import MainFooter from '../components/Footer/MainFooter'
import './RefundPolicy.css'

const TermCondition = () => {
    return (
        <div className="career-wrapper">
      <div className="refund-container">
        <div className="container">
          <div className="row">
            <h1 className="refund-title">
              Term & Conditions
            </h1>
    
            <p className='text'>Anda harus membaca, memahami, menerima dan menyetujui semua persyaratan dan ketentuan dalam ketentuan ini. Dengan mengakses atau menggunakan situs ini, Anda dianggap telah memahami dan menyetujui semua isi dalam syarat dan ketentuan di bawah ini. Syarat dan ketentuan dapat diubah atau diperbaharui sewaktu-waktu tanpa ada pemberitahuan terlebih dahulu. Perubahan dari syarat dan ketentuan akan segera berlaku setelah dicantumkan di dalam situs ini. Jika Anda merasa keberatan terhadap syarat dan ketentuan yang Kami ajukan, maka Kami anjurkan untuk tidak menggunakan situs ini.</p>
    
            <div className="refund-content">
              <ol className='item-list'>
                <li>Setiap pelanggan berkewajiban untuk membayar penuh atas transaksi yang dilakukan sesuai dengan ketentuan transaksi dan pembayaran.</li>
                <li>Setiap pelanggan bertanggung jawab atas seluruh hal yang dilakukan di situs www.cmd.co.id yang diatas nama pelanggan tersebut.</li>
                <li>Setiap pelanggan bertanggung jawab sepenuhnya jika pelanggan melakukan pelanggaran terhadap ketentuan-ketentuan yang telah dirincikan pada ketentuan ini dan menyetujui untuk melepaskan Kami atas seluruh kerugian yang diakibatkan oleh pelanggaran yang dilakukan oleh pelanggan.</li>
                <li>Pelanggan berhak mendapatkan barang/produk yang sesuai dan telah dibayar penuh oleh pelanggan, tidak dapat dikembalikan atau dibatalkan secara sepihak oleh pelanggan.</li>
                <li>Jika dalam keadaan tertentu, terdapat kesalahan harga ataupun informasi mengenai suatu produk tertentu yang disebabkan oleh kesalahan pengetikan (typo) atau kesalahan harga, informasi maupun human error dan system error, kami berhak untuk menolak ataupun membatalkan pesanan yang menggunakan harga yang salah termasuk pesanan yang sudah dibayarkan</li>
                <li>Kami bekerja sama dengan penyedia jasa perbankan/pembayaran yang terpercaya dalam menyediakan berbagai metode pembayaran yang dapat digunakan oleh pelanggan.</li>
                <li>Setiap pelanggan berhak untuk memilih metode pembayaran yang telah disediakan oleh Kami, yang mana pelanggan merasa lebih nyaman dan mudah dalam bertransaksi, dimana setiap biaya transaksi pembayaran akan ditangguhkan kepada pelanggan tersebut.</li>
                <li>Setiap pelanggan berkewajiban untuk membayar penuh atas pemesanan yang dilakukan dalam jangka waktu yang ditentukan oleh Kami untuk setiap metode pembayaran sebelum Kami dapat memproses lebih lanjut atas pemesanan pelanggan.</li>
                <li>Setiap pelanggan dapat mengklarifikasi transaksi pembayaran yang telah dilaksanakan secara langsung ke penyedia jasa perbankan yang bekerja sama dengan Kami sesuai dengan syarat dan ketentuan yang berlaku.</li>
                <li>Pada dasarnya semua pembayaran harus dilakukan melalui transfer bank atau bentuk transfer uang cepat lainnya ke rekening bank Kami yang ditunjuk. Pembayaran tunai yang merupakan jumlah penuh yang harus dibayarkan hanya dapat dilakukan dengan persetujuan tertulis dari Kami dan hanya kepada personel yang ditunjuk dari Kami. Personel tersebut hanya berwenang untuk menerima jumlah penuh yang harus dibayarkan dan harus mengirimkan pembayaran tunai dalam waktu 1 (satu) hari kerja kepada Kami atau membayarnya ke rekening bank yang ditunjuk Kami.</li>
                <li>Virtual Account adalah nomor identifikasi pelanggan Perusahaan yang disediakan oleh Bank atas permintaan Perusahaan untuk selanjutnya diberikan oleh Perusahaan kepada Anda sebagai nomor rekening tujuan penerimaan. Virtual Account merupakan suatu bentuk open payment (tidak memiliki jumlah tagihan yang pasti), dimana setiap setoran atas Virtual Account dibukukan oleh sistem ke rekening utama dengan mencantumkan nomor dan nama rekening Virtual. Dengan metode ini, pembayaran akan menjadi lebih mudah, yaitu anda tidak perlu melakukan konfirmasi pembayaran secara manual, melainkan sudah dibukukan langsung oleh sistem bank.</li>
                <li>Dengan melakukan pembayaran, anda menyetujui syarat dan ketentuan ini dan anda memahami untuk tidak menyerahkan bukti pembayaran dan/atau data pembayaran anda kepada pihak lain selain pihak perwakilan Perusahaan. Apabila terjadi kerugian akibat anda menyerahkan bukti pembayaran dan/atau data pembayaran anda kepada pihak ketiga, maka hal tersebut akan menjadi tanggung jawab anda.</li>
              </ol>
            </div>
            <p className="policy-description-footer">
            Jika ada hal yang ingin ditanyakan lebih lanjut, dengan senang hati PT Capella Multidana akan melayani melalui Whatsapp (WA) 081370377037 atau email: <a href="mailto:customer.service@cmd.co.id">customer.service@cmd.co.id</a>. Terima kasih atas kepercayaan Anda kepada PT Capella Multidana, Kami akan terus berkomitmen untuk memberikan pelayanan yang terbaik.
          </p>
          </div>
        </div>
      </div>
      <MainFooter />
    </div>
    
      )
}

export default TermCondition