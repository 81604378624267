import React from "react";
import { Accordion } from "@cmd/library";

const Bri = () => {
  return (
    <div className="paylease-accordion-container">
      <div className="mb--1">
        <Accordion title="ATM BRI">
          <ol type="1">
            <li>Masukkan kartu, kemudian pilih bahasa dan masukkan PIN anda</li>
            <li>Pilih "Transaksi Lain" dan pilih "Pembayaran"</li>
            <li>Pilih menu "Lainnya" dan pilih "Briva"</li>
            <li>Masukkan Nomor Virtual Account <b>92001988572642231</b> dan jumlah yang ingin anda bayarkan</li>
            <li>Periksa data transaksi dan tekan "YA"</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Internet Banking BRI">
          <ol type="1">
            <li>Buka situs <a href="https://ib.bri.co.id/id-bri/" target="_blank">https://ib.bri.co.id/id-bri/</a>, dan masukkan USER ID dan PASSWORD anda</li>
            <li>Pilih "Pembayaran" dan pilih "Briva"</li>
            <li>Masukkan Nomor Virtual Account <b>92001988572642231</b> dan jumlah yang ingin anda bayarkan</li>
            <li>Masukkan password anda kemudian masukkan mToken internet banking</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Mobile Banking BRI">
          <ol type="1">
            <li>Buka aplikasi BRI Mobile Banking, masukkan USER ID dan PIN anda</li>
            <li>Pilih "Pembayaran" dan pilih "Briva"</li>
            <li>Masukkan Nomor Virtual Account <b>92001988572642231</b> dan jumlah yang ingin anda bayarkan</li>
            <li>Masukkan PIN Mobile Banking BRI</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
    </div>
  )
}

export default Bri;
