import React from 'react'

import './MainFooter.css'

const MainFooter = () => {
  return (
    <footer className="main-footer">
      <div className="container">
        <div className="top-footer row">
          <div className="col-3">
            <div className="text--size-12 footer-inline-text mb--1">
            PT. Capella Multidana Berizin dan Diawasi Otoritas Jasa Keuangan
            </div>
          </div>
          <div className="col-3">
            <div className="text--size-12 footer-inline-text mb--1">
              Anggota dari
            </div>
            <div className="mr--1 mb-sm--1 top-footer-logo">
              <img className="img-absolute-bottom" src={require('../../assets/img/appi.png')} alt=""/>
            </div>
          </div>
        </div>
        <div className="bottom-footer row justify-content-between align-items-center">
          <div className="col-md-3">
            <div className="main-footer-copy">
              <span className="text--size-12">© 2020 PT. Capella Multidana</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="fx justify-content-between main-footer-social">
              <span onClick={() => window.open(`https://www.tiktok.com/@cmdfinance?is_from_webapp=1&sender_device=pc`, "_blank")} className="cursor-pointer"><img className="social-height" src={require('../../assets/img/tik-tok.svg')}></img></span>
              <span onClick={() => window.open(`https://www.instagram.com/cmdfinance/`, "_blank")} className="cursor-pointer"><img className="social-height" src={require('../../assets/img/instagram.svg')}></img></span>
              <span onClick={() => window.open(`https://www.facebook.com/cmdfinance`, "_blank")} className="cursor-pointer"><img className="social-height" src={require('../../assets/img/facebook.svg')}></img></span>
              <span onClick={() => window.open(`https://www.linkedin.com/company/pt-capella-multidana/?originalSubdomain=id`, "_blank")} className="cursor-pointer"><img className="social-height" src={require('../../assets/img/linkedin.svg')}></img></span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default MainFooter