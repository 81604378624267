import React from 'react';
import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown,
  faCircleNotch,
  faCheck,
  faMinus,
  faTimes,
  faPlus,
  faCalendarAlt,
  faStar
} from '@fortawesome/free-solid-svg-icons'

import App from './App';
import * as serviceWorker from './serviceWorker';

// GLOBAL STYLING
import '@cmd/library/lib/css/vendors/reboot.css';
import '@cmd/library/lib/css/vendors/layout.css';
import '@cmd/library/lib/css/var.css';
import '@cmd/library/lib/css/typography.css';
import '@cmd/library/lib/css/global.css';
import 'react-input-range/lib/css/index.css';

library.add(faCheck, faTimes, faAngleDown, faPlus, faMinus, faCircleNotch, faCalendarAlt, faStar);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
