import React from "react";
import { Accordion } from "@cmd/library";

const Mandiri = () => {
  return (
    <div className="paylease-accordion-container">
      <div className="mb--1">
        <Accordion title="ATM Mandiri">
          <ol type="1">
            <li>Masukan ATM dan tekan "Bahasa Indonesia"</li>
            <li>Masukan PIN, lalu tekan "Benar"</li>
            <li>Pilih "Pembayaran", lalu pilih "Multi Payment"</li>
            <li>Masukkan kode perusahaan (88908 XENDIT), lalu tekan 'BENAR'</li>
            <li>Masukkan Nomor Virtual Account <b>88908988586665460 (contoh)</b>, lalu tekan 'BENAR'</li>
            <li>Informasi pelanggan akan ditampilkan, pilih nomor 1 sesuai dengan nominal pembayarna kemudian tekan "YA"</li>
            <li>Pastikan nominal muncul sesuai dengan tagihan</li>
            <li>Konfirmasi pembayaran akan muncul, tekan "YES", untuk melanjutkan</li>
            <li>Simpan bukti transaksi anda</li>
            <li>Transaksi anda berhasil</li>
            <li>Setelah transaksi anda selesai, invoice ini akan dipudate secara otomatis. Proses ini  mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Internet Banking Mandiri">
          <ol type="1">
            <li>Buka situs Mandiri Internet Banking <a href="https://ibank.bankmandiri.co.id" target="_blank">https://ibank.bankmandiri.co.id</a></li>
            <li>Masuk menggunakan USER ID dan PASSWORD</li>
            <li>Buka halaman beranda, kemudian pilih "Pembayaran"</li>
            <li>Pilih "Multi Payment"</li>
            <li>Pilih 88908 XENDIT seabagai penyedia jasa</li>
            <li>Masukkan Nomor Virtual Account <b>88908988586665460 (contoh)</b></li>
            <li>Lalu pilih Lanjut</li>
            <li>Pastikan nominal muncul sesuai dengan tagihan</li>
            <li>Apabila semua detail benar tekan "KONFIRMASI"</li>
            <li>Masukan PIN / Challenge Code Token</li>
            <li>Setelah transaksi pembayaran Anda selesai, simpan bukti pembayaran</li>
            <li>Invoice ini akan diperbarui secara otomatis. Ini bisa memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Mobile Banking (New Livin by Mandiri - Yellow)">
          <ol type="1">
            <li>Buka aplikasi Livin by Mandiri, masukkan PASSWORD atau lakukan verifikasi wajah</li>
            <li>Pilih menu "IDR Transfer"</li>
            <li>Pilih "Transfer to new recipient"</li>
            <li>Masukkan Nomor Virtual Account <b>88908988586665460 (contoh)</b></li>
            <li>Konfirmasi detail VA dan klik "Continue"</li>
            <li>Pastikan nominal muncul sesuai dengan tagihan</li>
            <li>Tinjau dan konfirmasi detail transaksi anda, lalu klik "Continue"</li>
            <li>Selesaikan transaksi dengan memasukkan MPIN anda</li>
            <li>Setelah transaksi pembayaran Anda selesai, simpan bukti pembayaran</li>
            <li>Invoice ini akan diperbarui secara otomatis.</li>
          </ol>
        </Accordion>
      </div>
    </div>
  )
}

export default Mandiri;
