import React, { useEffect, useState } from "react";
import momentTz from "moment-timezone";
import moment from "moment";
import Countdown from "react-countdown";
import { QRCode } from 'react-qrcode-logo';
import { withRouter } from "react-router-dom";
import { Button, Wizard } from "@cmd/library";
import { rupiah } from "@cmd/library/lib/utils/function";

import { httpClient } from "../../utils/httpClient";
import { withContext } from '../../context/withContext';
import Sidebar from "./Sidebar";
import { Permata, Mandiri, Bri, Bni, Cimb, Bsi, Ovo, Bca } from "./PaymentInstruction";

moment.locale("id");
momentTz.defineLocale("id", moment.localeData()._config);
momentTz.tz.setDefault("Asia/Jakarta");

const PaymentResult = ({ match, context, history }) => {
  const id = match.params ? match.params.id : null;
  const [payment, setPayment] = useState(true);
  const [copyText, setCopyText] = useState('');
  const [copied, setCopied] = useState(false);

  const fetchData = async () => {
    const result = await httpClient.get(`/lease/status?trx_id=${id}`);

    setPayment(result.data);
  };

  const handleCopy = async () => {
    setCopyText(payment.va_number)
    navigator.clipboard.writeText(copyText)
    .then(() => {
      setCopied(true)
      setTimeout(() => {
        setCopied(false);
      }, 700);
    }) 
    .catch(err => err);
}

  const checkPaymentStatus = () => fetchData();

  const onClickHome = () => {
    context.setPayleaseWizardStep(1)
    history.push('/pembayaran')
  }

  useEffect(() => { fetchData() }, []);

  return (
    <div className="main-page">
      <div className="container-fluid">
        <div className="row justify-content-end">
          <div className="col-md-4 sidebar-col">
            <div className="sidebar-page-wrapper">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-8">
            <div className="main-page-wrapper">
              <div className="row">
                {
                  payment.status === 'PENDING' && (
                    <div className="col-lg-8 col-md-12">
                      <Wizard steps={[
                        {
                          text: 'No Tagihan',
                          completed: true
                        },
                        {
                          text: 'Detail Tagihan',
                          completed: true
                        },
                        {
                          text: 'Pilih Metode',
                          completed: true
                        },
                        {
                          text: 'Pembayaran',
                          completed: true
                        }
                      ]} />
                      <div className="step1-container">
                        <div className="row row-gap">
                          <div className="col-12">
                            <div>
                              <h2 className="text--weight-500">
                                Mohon selesaikan pembayaran dalam waktu
                              </h2>
                              <div className="box-timer">
                                <div className="box-timer--countdown">
                                  <Countdown
                                    date={momentTz(payment.expired).format("YYYY-MM-DDTHH:mm:ssZ")}
                                  />
                                </div>
                                <div>
                                  <p className="text--weight-700">Batas Akhir Pembayaran</p>
                                  <h2 className="text--weight-500 mb--0">
                                    {momentTz(payment.expired).format("dddd, L, HH:mm zz")}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row-hr"></div>
                        { payment && payment.payment_name !== 'QRIS' && (
                          <div className="row row-gap">
                            <div className="col-12">
                              <div>
                                <p className="text--size-12"><strong>Metode Pembayaran</strong></p>
                                <h2 className="text--weight-500">{payment.payment_method} - {payment.payment_name}</h2>
                              </div>
                            </div>
                          </div>
                        ) }
                        <div className="row row-gap">
                          <div className="col-md-6">
                            { payment && payment.payment_name !== 'QRIS' ? (
                              <div>
                                <p className="text--size-12"><strong>{payment.payment_method === 'E-Wallet' ? "Nomor Handphone": "Nomor VA"}</strong></p>
                                <div className="flex-left">
                                  <h2 className="text--weight-500 mr--0-5">{payment.va_number}</h2>
                                  <div className="flex-center">
                                    <div className="flex-icon">
                                      <img src={require("../../assets/img/copy-file.svg")} onClick={handleCopy} className="img-icon"/>
                                    </div>
                                    <p className="text--size-12 text--weight-700  mb--0">{copied ? 'Copied' : ''}</p>
                                  </div>
                                </div>
                            
                                {/* <p className="text--size-12">Rekening a.n. Capella Multidana</p> */}
                              </div>
                            ) : (
                              <div>
                                <p className="text--size-12"><strong>QRIS code</strong></p>
                                <div className="mb--1">
                                  <div className="qris-code">
                                    <QRCode
                                      size={256}
                                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                      value={payment.qr_code}
                                      viewBox={`0 0 256 256`}
                                      logoImage={require('../../assets/img/cmd-logo.png')}
                                      removeQrCodeBehindLogo={true}
                                      eyeColor="#eeb104"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-4">
                                    <div className="mb--1">
                                      <img src={require('../../assets/img/gopay.png')} alt=""/>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="mb--1">
                                      <img src={require('../../assets/img/ovo.png')} alt=""/>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="mb--1">
                                      <img src={require('../../assets/img/dana.png')} alt=""/>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="mb--1">
                                      <img src={require('../../assets/img/shopeepay.png')} alt=""/>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="mb--1">
                                      <img src={require('../../assets/img/linkaja.png')} alt=""/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) }
                          </div>
                          <div className="col-md-6">
                            <div>
                              <p className="text--size-12"><strong>Total Pembayaran</strong></p>
                              <h2 className="text--weight-500">{rupiah(payment.amount)}</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row row-gap">
                          <div className="col-md-6 mb--1">
                              <Button onClick={checkPaymentStatus} text="Cek Status Pembayaran" />
                          </div>
                          <div className="col-md-6">
                            <Button
                              text="Kembali ke Laman Utama"
                              className="btn--accent"
                              onClick={onClickHome}
                            />
                          </div>
                        </div>
                        { payment && payment.payment_name !== 'QRIS' && (
                          <>
                            <div className="row-hr"></div>
                            <div className="row">
                              <div className="col">
                                <div className="mb--2">
                                  <p className="text--size-12"><strong>Panduan Pembayaran</strong></p>
                                </div>
                                {payment && payment.payment_name === 'OVO' && <Ovo/>}
                                {/* {payment && payment.payment_name === 'LINKAJA' && <Linkaja/>} */}
                                {payment && payment.payment_name === 'BSI' && <Bsi/>}
                                {payment && payment.payment_name === 'PERMATA' && <Permata/>}
                                {payment && payment.payment_name === 'MANDIRI' && <Mandiri/>}
                                {payment && payment.payment_name === 'BRI' && <Bri/>}
                                {payment && payment.payment_name === 'BNI' && <Bni/>}
                                {payment && payment.payment_name === 'CIMB' && <Cimb/>}
                                {payment && payment.payment_name === 'BCA' && <Bca/>}
                                {/* {payment && payment.payment_name === 'MAYBANK' && <Maybank/>}
                                {payment && payment.payment_name === 'DANAMON' && <Danamon/>} */}
                              </div>
                            </div>
                          </>
                        ) }
                      </div>
                    </div>
                  )
                }
                {
                  ['SUCCESS', 'EXPIRED'].includes(payment.status) && (
                    <div className="col-lg-8 col-md-12">
                      <div className="row-gap">
                        <h2 className="text--weight-500">
                          {
                            payment.status === 'SUCCESS' ?
                            "Pembayaran Berhasil. Terima kasih telah menyelesaikan transaksi di Capella Multidana" :
                            <>
                              <p>Pembayaran Kami Batalkan.</p>
                              <p>Anda telah melewati batas waktu pembayaran</p>
                            </>
                          }
                        </h2>
                      </div>
                      <div className="row row-gap">
                        <div className="col-md-12">
                          <div>
                            <p className="text--size-12"><strong>Metode Pembayaran</strong></p>
                            <h2 className="text--weight-500">{payment.payment_method} - {payment.payment_name}</h2>
                          </div>
                        </div>
                      </div>
                      <div className="row row-gap">
                        <div className="col-md-12">
                          <div>
                            <p className="text--size-12"><strong>Total Pembayaran</strong></p>
                            <h2 className="text--weight-500">{rupiah(payment.amount ? payment.amount : 0)}</h2>
                          </div>
                        </div>
                      </div>
                      {
                        payment.status === 'SUCCESS' && (
                      <div className="row row-gap">
                        <div className="col-md-12">
                          <div>
                            <p className="text--size-12"><strong>Waktu Pembayaran</strong></p>
                            <h2 className="text--weight-500">{momentTz(payment.payment_datetime).format('dddd, L, HH:mm zz')}</h2>
                          </div>
                        </div>
                      </div>
                        )
                      }
                      <div className="row row-gap">
                        <div className="col-md-12">
                          <div>
                            <p className="text--size-12"><strong>Kode Pembayaran</strong></p>
                            <h2 className="text--weight-500">{payment.trx_id}</h2>
                          </div>
                        </div>
                      </div>
                      <div className="row row-gap">
                        <div className="col-md-12">
                          <Button text="Kembali ke Laman Utama" onClick={onClickHome} />
                        </div>
                      </div>
                  </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(withContext(PaymentResult));
