import React from 'react'
import Modal from 'react-modal';
import MainFooter from '../components/Footer/MainFooter'

import './RefundPolicy.css'

Modal.setAppElement('#root');

const RefundPolicy = () => {


  return (
    <div className="career-wrapper">
  <div className="refund-container">
    <div className="container">
      <div className="row">
        <h1 className="refund-title">
          Refund Policy
        </h1>

        <h2>Silakan baca kebijakan ini dengan seksama karena di dalamnya mengandung informasi penting sebelum menggunakan layanan Kami.</h2>

        <div className="refund-content">
          <h3>1. Kebijakan Umum</h3>
          <p className='text-p'>
            Ketentuan kebijakan berlaku untuk semua layanan yang di tawarkan oleh PT Capella Multidana yang di dalam kebijakan ini selanjutnya disebut dengan "Produk". Di dalam dokumen kebijakan ini "Kami" merujuk pada PT Capella Multidana dan "Anda" merujuk pada Pelanggan.
          </p>

          <h3>2. Tidak Ada Kebijakan Pengembalian Dana <i>(Refund Policy)</i></h3>
          <p className='text-p'>
            <ul>
              <li>Kami tidak menyediakan pengembalian dana apabila produk telah di beli dan di terima oleh Anda atau angsuran yang telah dibayar oleh Anda sesuai ketentuan yang tercantum.</li>
              <li>Kami tidak bertanggungjawab apabila terdapat kelalaian dan perbuatan bertentangan yang dilakukan oleh Anda dengan alasan apa apun tidak diperkenankan pengembalian dana.</li>
            </ul>
          </p>

          <h3>3. Keadaan Kahar <i>(Force Majeure)</i></h3>
          <p className='text-p'>
            Produk yang ditangguhkan, dibatalkan atau dihentikan akibat bencana alam tidak berlaku adanya pengembalian dana.
          </p>

          <p className="policy-description-footer">
            Jika ada hal yang ingin ditanyakan lebih lanjut, dengan senang hati PT Capella Multidana akan melayani melalui Whatsapp (WA) 081370377037 atau email: <a href="mailto:customer.service@cmd.co.id">customer.service@cmd.co.id</a>. Terima kasih atas kepercayaan Anda kepada PT Capella Multidana, Kami akan terus berkomitmen untuk memberikan pelayanan yang terbaik.
          </p>
        </div>
      </div>
    </div>
  </div>
  <MainFooter />
</div>

  )
}

export default RefundPolicy
