import React from 'react'

import './Footer.css'

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="row footer-inline-logo">
        <div className="col-6 col-sm-6 col-md-5">
          <div className="footer-inline-logo">
            <div className="text--size-12 footer-inline-text">
            PT. Capella Multidana Berizin dan Diawasi Otoritas Jasa Keuangan
            </div>
            {/* <div>
              <img src={require('../assets/img/ojk.svg')} alt=""/>
            </div> */}
          </div>
        </div>
        <div className="col-6 col-sm-6 col-md-3">
          <div className="footer-inline-logo">
            <div className="text--size-12 footer-inline-text">
              Anggota dari
            </div>
            <div className="row">
              <div className="col-6 col-sm-6 col-md-6">
                <div className="mr--1 mb-sm--1">
                  <img src={require('../assets/img/appi.svg')} alt=""/>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6">
              <div>
              <img src={require('../assets/img/bmppvi.png')} alt=""/>
            </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="text--size-12 footer-copyright">
            &copy; 2020 PT. Capella Multidana
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
