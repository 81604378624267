import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route
} from 'react-router-dom'

import MainHeader from './components/MainHeader'
import MegaMenu from './components/MegaMenu'
import Loan from './pages/Danatunai/Loan'
import Contact from './pages/Danatunai/Contact'
import OTP from './pages/Danatunai/OTP'
import PersonalInformation from './pages/Danatunai/PersonalInformation'
import Guarantee from './pages/Danatunai/Guarantee'
import Success from './pages/Danatunai/Success'
// import Calculator from './components/Calculator'
import Danatunai from './pages/Danatunai'

import ContextProvider from './context/ContextProvider'
// PAGES
import Paylease from './pages/Paylease';
import PaymentResult from "./pages/Paylease/PaymentResult";
import Career from './pages/Career'
import RefundPolicy from './pages/RefundPolicy'
import TermCondition from './pages/TermCondition'
import Home from './pages/Home'

import './App.css';

const routes = [
  {
    path: '/pinjaman',
    component: Danatunai,
    routes: [
      {
        path: '/pinjaman',
        exact: true,
        component: Loan
      },
      {
        path: '/pinjaman/contact',
        component: Contact
      },
      {
        path: '/pinjaman/otp',
        component: OTP
      },
      {
        path: '/pinjaman/personal-information',
        component: PersonalInformation
      },
      {
        path: '/pinjaman/guarantee',
        component: Guarantee
      },
      {
        path: '/pinjaman/loan-submitted',
        component: Success
      }
    ]
  },
  {
    path: '/pembayaran',
    component: Paylease,
    exact: true,
  },
  {
    path: '/pembayaran/:id',
    component: PaymentResult,
  },
  {
    path: '/karir',
    component: Career
  },
  {
    path: '/refund-policy',
    component: RefundPolicy
  },
  {
    path: '/term-and-conditions',
    component: TermCondition
  }
]

export function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => (
        <route.component {...props} routes={route.routes} />
      )}
    />
  )
}

const App = () => {
  return (
    <ContextProvider>
      <Router>
        <MainHeader />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          { routes.map((route, i) => (
            <RouteWithSubRoutes key={`route-${i}`} {...route} />
          )) }
          <Route path="/karir">
            <Career />
          </Route>
        </Switch>
        <MegaMenu />
      </Router>
    </ContextProvider>
  );
}

export default App;
