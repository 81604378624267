import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import './Review.css'

const Review = () => {
  return (
    <div>
      <Swiper
        spaceBetween={32}
        breakpoints={{
          320: {
            // width: 640,
            slidesPerView: 1.3,
          },
          768: {
            slidesPerView: 3.7,
          },
        }}
        slidesPerView={3.7}>
          <SwiperSlide>
            <div className="review-box">
              <div className="review-box-avatar mb--1">
                <div className="mr--1">
                  <img src={require('../../assets/img/review-1.png')} alt=""/>
                </div>
                <div>
                  <p className="mb--0"><strong>Nurhidayat (Perawang)</strong></p>
                </div>
              </div>
              <div>
                <p>
                  Angsuran pinjaman dana di CMD lebih ringan dibandingkan dengan kompetitor
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="review-box">
              <div className="review-box-avatar mb--1">
                <div className="mr--1">
                  <img src={require('../../assets/img/review-2.png')} alt=""/>
                </div>
                <div>
                  <p className="mb--0"><strong>Aneka Widianto (Pekanbaru)</strong></p>
                </div>
              </div>
              <div>
                <p>
                  Fee menarik. Jadi tambahan untuk beli susu anak
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="review-box">
              <div className="review-box-avatar mb--1">
                <div className="mr--1">
                  <img src={require('../../assets/img/review-3.png')} alt=""/>
                </div>
                <div>
                  <p className="mb--0"><strong>Jasa Rigscott (Pekanbaru)</strong></p>
                </div>
              </div>
              <div>
                <p>
                  Cukup memberikan No HP kepada team Capella, langsung di follow up sampai tuntas. Fee juga menarik!
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="review-box">
              <div className="review-box-avatar mb--1">
                <div className="mr--1">
                  <img src={require('../../assets/img/review-4.png')} alt=""/>
                </div>
                <div>
                  <p className="mb--0"><strong>Sunardi (Pangkalan Kerinci)</strong></p>
                </div>
              </div>
              <div>
                <p>
                  Terima Kasih Capella Multidana, Fee nya  sangat menarik. Senang bisa bekerja sama menjadi agen pinjaman dana tunai Capella Multidana
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="review-box">
              <div className="review-box-avatar mb--1">
                <div className="mr--1">
                  <img src={require('../../assets/img/review-5.png')} alt=""/>
                </div>
                <div>
                  <p className="mb--0"><strong>Agustiar (Biruen)</strong></p>
                </div>
              </div>
              <div>
                <p>
                  Cmd bestt!! Begitu ada berkas, langsung proses. Tidak mengecewakan kalau memang layak, langsung proses sampai tuntas.
                </p>
              </div>
            </div>
          </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Review