import React, { useEffect, useState } from 'react'
import { Button } from '@cmd/library'
import Modal from 'react-modal';
import { httpClient } from '../utils/httpClient';

import Select from '../components/Form/Select'
import AccordionCareer from '../components/Accordion/AccordionCareer'
import MainFooter from '../components/Footer/MainFooter'

import './Career.css'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,.7)',
    zIndex: 9999
  },
  content : {
    border: 0,
    borderRadius: 0,
    padding: 0,
    top : '50%',
    left : '50%',
    right : 'auto',
    bottom : 'auto',
    transform : 'translate(-50%, -50%)'
  }
};

Modal.setAppElement('#root');

const Career = () => {
  const [careerLocation, setCareerLocation] = useState({
    value: 'Semua Cabang',
    label: 'Semua Cabang'
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [cities, setCities] = useState([]);
  const [careers, setCareers] = useState([]);
  const [careerList, setCareerList] = useState([]);
  const [career, setCareer] = useState({});

  const fetchCareers = async () => {
    const careerResults = await httpClient.get('/career');
    const cities = []
    Object.keys(careerResults.data).map((career) => {
      cities.push({ value: career, label: career })
    });
    setCities(cities);
    setCareers(careerResults.data)
  };

  useEffect(() => {
    fetchCareers();
  }, []);


  const setLocation = (selectValue) => {
    setCareerLocation(selectValue)
    setCareerList(careers[`${selectValue}`]);
  }

  const openModal = (index) => {
    setModalOpen(true);
    setCareer(careerList[index])
  }


  return (
    <div className="career-wrapper">
      <div className="career-hero">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h1 className="title-big">
                Careers at CMD
              </h1>
            </div>
            <div className="col-md-7">
              <p>
                We believe that access to financial freedom is a fundamental human right. Our mission is to make sure everyone has the opportunity to exercise that right by making simple, sophisticated, and affordable financial products.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-page">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div>
                <Select
                  placeholder="Choose Location"
                  value={careerLocation}
                  options={cities}
                  onChange={(selectValue) => setLocation(selectValue)} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {
                  careerList.length != 0 && careerList.map( (career,index) => (
                    <div className="career-accordion-row" onClick={() => openModal(index)}>
                      <AccordionCareer location={career.City.name} job={career.title} />
                    </div>
                  ))
              }
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customStyles}
      >
        <div className="modal-career-wrapper">
          <div className="modal-career-header">
            <img src={require('../assets/img/career-modal.jpg')} alt=""/>
            <div className="modal-career-header-text">
              <p>{careerLocation}</p>
              <h1 className="title-big">
                {career.title}
              </h1>
            </div>
            <div className="modal-career-header-button">
              <Button text="Apply this position" />
            </div>
          </div>
          <div className="modal-career-body">
            <div className="mb--1">
              <p>
                <strong>In this role, you will have the opportunity to:</strong><br/>
                {career.description}
              </p><br/>
              <p>
                <strong>Skills we’re looking for:</strong><br/>
                {career.skills}
              </p>
            </div>
            <div>
              <Button text="Apply this position" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Career
