import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import './Select.css'

const Select = ({ label = null, options, id, value, placeholder, onChange = () => {} }) => {
  const [showSelect, setShowSelect] = useState(false)
  const [selectLabel, setSelectLabel] = useState('')
  const ref = useRef();

  const handleSelectClick = (value, label) => {
    onChange(value)
    setSelectLabel(label)
    setShowSelect(false)
  }

  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowSelect(false)
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  return (
    <div className="select-dummy" ref={ref}>
      <div className="select-label" onClick={() => setShowSelect(!showSelect)}>
        { label && (
          <label htmlFor={id}>{label}</label>
        ) }
        <div className="select-value-wrapper mb--0">
          <span className={`select-value-item ${!selectLabel ? 'select-placeholder' : ''}`}>{selectLabel ? selectLabel : placeholder}</span>
          <span className="ml--2 select-dummy-arrow">
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
        </div>
      </div>
      <div className={`select-custom-dropdown ${showSelect ? 'select-custom-dropdown--show' : ''}`}>
        <ul>
          { options.map((option) => <li key={option.value} onClick={() => handleSelectClick(option.value, option.label)}>{option.label}</li>) }
        </ul>
      </div>
    </div>
  )
}

export default Select
