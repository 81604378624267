import React from "react";
import { Accordion } from "@cmd/library";

const Bsi = () => {
  return (
    <div className="paylease-accordion-container">
      <div className="mb--1">
        <Accordion title="ATM BSI">
          <ol type="1">
            <li>Masukkan kartu ATM BSI anda</li>
            <li>Masukkan PIN</li>
            <li>Pilih menu "Pembayaran/Pembelian"</li>
            <li>Pilih menu "Institusi"</li>
            <li>Masukkan kode BSI VA Nomor Virtual Account Contoh: 9347xxxxxxxxxx</li>
            <li>Detail yang ditampilkan: NIM, Nama, & Total Tagihan</li>
            <li>Konfirmasi detail transaksi anda</li>
            <li>Transaksi Anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Internet Banking BSI">
          <ol type="1">
            <li>Buka situs <a href="https://bsinet.bankbsi.co.id" target="_blank">https://bsinet.bankbsi.co.id</a></li>
            <li>Masukkan User ID dan Password</li>
            <li>Pilih Menu “Pembayaran”</li>
            <li>Pilih Nomor Rekening BSI Anda</li>
            <li>Pilih menu "Institusi"</li>
            <li>Masukkan nama institusi Xendit (kode 9347)</li>
            <li>Masukkan Nomor Virtual Account tanpa diikuti kode institusi (tanpa 4 digit pertama). Contoh: 988619428280</li>
            <li>Konfirmasi detail transaksi anda</li>
            <li>Masukkan otentikasi transaksi/token</li>
            <li>Transaksi Anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Mobile Banking BSI">
          <ol type="1">
            <li>Buka aplikasi BSI Mobile</li>
            <li>Masukkan User ID dan Password</li>
            <li>Pilih Menu “Pembayaran”</li>
            <li>Pilih Nomor Rekening BSI Anda</li>
            <li>Pilih menu "Institusi"</li>
            <li>Masukkan nama institusi Xendit (kode 9347)</li>
            <li>Masukkan Nomor Virtual Account tanpa diikuti kode institusi (tanpa 4 digit pertama). Contoh: 988619428280</li>
            <li>Konfirmasi detail transaksi anda</li>
            <li>Masukkan otentikasi transaksi/token</li>
            <li>Transaksi Anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Interbank BSI">
          <ol type="1">
            <li>Buka aplikasi Mobile bank Anda</li>
            <li>Masukkan User ID dan Password</li>
            <li>Pilih Menu “Pembayaran”</li>
            <li>Pilih Menu “Antar Bank”</li>
            <li>Masukkan BSI VA Code “900” + Nomor Virtual Account. Contoh: 9009347988619428280 <br/>
            Catatan: Untuk transfer antar bank via ATM, Anda harus memasukkan Kode Bank BSI “451” sebelum Kode BSI VA “900”</li>
            <li>Masukkan nominal yang ingin dibayarkan</li>
            <li>Pilih tipe rekening</li>
            <li>Konfirmasi detail transaksi anda</li>
            <li>Transaksi Anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
    </div>
  )
}

export default Bsi;
