import React from "react";
import { Accordion } from "@cmd/library";

const Ovo = () => {
  return (
    <div className="paylease-accordion-container">
      <div className="mb--1">
        <Accordion title="Aplikasi OVO">
          <ol type="1">
            <li>Buka aplikasi OVO untuk menyelesaikan pembayaran</li>
            <li>Buka inbox di aplikasi OVO jika tidak mendapatkan notifikasi.</li>
            <li>Periksa ulang jumlah tagihan yang muncul di aplikasi OVO.</li>
            <li>Klik Bayar dan masukkan PIN akun OVO kamu.</li>
            <li>Pembayaran berhasil. </li>
          </ol>
        </Accordion>
      </div>
    </div>
  )
}

export default Ovo;
