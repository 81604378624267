import React, {useEffect, useState} from 'react'
import { Button } from '@cmd/library'

import CalculatorContent from '../../components/CalculatorContent'
import Testimoni from './Testimoni'
import Review from './Review'
import MainFooter from '../../components/Footer/MainFooter'
import { httpClient } from '../../utils/httpClient'
import { rupiah } from '@cmd/library/lib/utils/function';

import './Home.css'
import 'swiper/swiper-bundle.css';

const Home = () => {
  const [flip1, setFlip1] = useState(Math.random() < 0.5)
  const [flip2, setFlip2] = useState(Math.random() < 0.5)
  const [flip3, setFlip3] = useState(Math.random() < 0.5)
  const [flip4, setFlip4] = useState(Math.random() < 0.5)
  const [flip5, setFlip5] = useState(Math.random() < 0.5)
  const [flip6, setFlip6] = useState(Math.random() < 0.5)
  const [carCount, setCarCount] = useState(0)
  const [bikeCount, setBikeCount] = useState(0)
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState(null);
  const [data4, setData4] = useState(null);
  const [data5, setData5] = useState(null);
  const [data6, setData6] = useState(null);
  const [allCar, setAllCar] = useState([]);
  const [allBike, setAllBike] = useState([]);

  useEffect(() => {
    
    const initial = async() => {
      let carIndex = 0;
      let bikeIndex = 0;
  
      const car = await httpClient.get(`/inventory?isCar=Mobil&isInternal=false&limit=50&offset=0`);
      const bike = await httpClient.get(`/inventory?isCar=Motor&isInternal=false&limit=50&offset=0`)
  
      const data1 = flip1 ? car.data.rows[carIndex] : bike.data.rows[bikeIndex];
      carIndex = flip1 ? carIndex + 1 : carIndex;
      bikeIndex = !flip1 ? bikeIndex + 1 : bikeIndex;
      const data2 = flip2 ? car.data.rows[carIndex] : bike.data.rows[bikeIndex];
      carIndex = flip2 ? carIndex + 1 : carIndex;
      bikeIndex = !flip2 ? bikeIndex + 1 : bikeIndex;
      const data3 = flip3 ? car.data.rows[carIndex] : bike.data.rows[bikeIndex];
      carIndex = flip3 ? carIndex + 1 : carIndex;
      bikeIndex = !flip3 ? bikeIndex + 1 : bikeIndex;
      const data4 = flip4 ? car.data.rows[carIndex] : bike.data.rows[bikeIndex];
      carIndex = flip4 ? carIndex + 1 : carIndex;
      bikeIndex = !flip4 ? bikeIndex + 1 : bikeIndex;
      const data5 = flip5 ? car.data.rows[carIndex] : bike.data.rows[bikeIndex];
      carIndex = flip5 ? carIndex + 1 : carIndex;
      bikeIndex = !flip5 ? bikeIndex + 1 : bikeIndex;
      const data6 = flip6 ? car.data.rows[carIndex] : bike.data.rows[bikeIndex];
      carIndex = flip6 ? carIndex + 1 : carIndex;
      bikeIndex = !flip6 ? bikeIndex + 1 : bikeIndex;
  
      setCarCount(car.data.count > bike.data.count ? parseInt(bike.data.count/6) * 6 : parseInt(car.data.count/6) * 6)
      setBikeCount(car.data.count > bike.data.count ? parseInt(bike.data.count/6) * 6 : parseInt(car.data.count/6) * 6)
      setAllCar(car.data.rows)
      setAllBike(bike.data.rows)
      setData1(data1)
      setData2(data2)
      setData3(data3)
      setData4(data4)
      setData5(data5)
      setData6(data6)
    }

    initial();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {  //assign interval to a variable to clear it.
      setFlip1(!flip1);
      return flipData(1, data1)
    }, 4500)

  
    return () => clearInterval(interval) //This is important
   
  }, [data1])

  useEffect(() => {
    const interval = setInterval(() => {  //assign interval to a variable to clear it.
      setFlip2(!flip2);
      return flipData(2, data2)
    }, 3500)

  
    return () => clearInterval(interval) //This is important
   
  }, [data2])

  useEffect(() => {
    const interval = setInterval(() => {  //assign interval to a variable to clear it.
      setFlip3(!flip3);
      return flipData(3, data3)
    }, 2500)

  
    return () => clearInterval(interval) //This is important
   
  }, [data3])

  useEffect(() => {
    const interval = setInterval(() => {  //assign interval to a variable to clear it.
      setFlip4(!flip4);
      return flipData(4, data4)
    }, 3000)

  
    return () => clearInterval(interval) //This is important
   
  }, [data4])

  useEffect(() => {
    const interval = setInterval(() => {  //assign interval to a variable to clear it.
      setFlip5(!flip5);
      return flipData(5, data5)
    }, 4000)

  
    return () => clearInterval(interval) //This is important
   
  }, [data5])

  useEffect(() => {
    const interval = setInterval(() => {  //assign interval to a variable to clear it.
      setFlip6(!flip6);
      return flipData(6, data6)
    }, 7000)

  
    return () => clearInterval(interval) //This is important
   
  }, [data6])

  const flipData = async(card_number, data) => {
    const flip = card_number == 1 ? flip1 : card_number == 2 ? flip2 : card_number == 3 ? flip3 : card_number == 4 ? flip4 : card_number == 5 ? flip5 : flip6;

    let index = 0;

    if (data.CarType) {
      index = allCar.findIndex(x => x.id === data.id) + 6;
      if (index >= carCount) {
        index = index - carCount;
      }
    } else {
      index = allBike.findIndex(x => x.id === data.id) + 6;
      if (index >= bikeCount) {
        index = index - bikeCount;
      }
    }

    if ( card_number == 1 ) setData1(!flip ? allCar[index] : allBike[index]);
    if ( card_number == 2 ) setData2(!flip ? allCar[index] : allBike[index]);
    if ( card_number == 3 ) setData3(!flip ? allCar[index] : allBike[index]);
    if ( card_number == 4 ) setData4(!flip ? allCar[index] : allBike[index]);
    if ( card_number == 5 ) setData5(!flip ? allCar[index] : allBike[index]);
    if ( card_number == 6 ) setData6(!flip ? allCar[index] : allBike[index]);

  }

  return (
    <div className="home-page">
      <section className="section-calculator section-wrapper">
        <div className="container">
          <div className="row mb--2">
            <div className="col-md-6">
              <div className="mb--1">
                <div className="mb--1 text--center calculator-hero-art">
                  <img src={require('../../assets/img/time-art.png')} alt=""/>
                </div>
                <div className="text--center">
                  <h1 className="title-big">Pinjaman Cepat Angsuran Hemat</h1>
                  <p>
                    5 menit pengajuan. 10 menit survey. 15 menit pencairan
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="calculator-home-wrapper">
                <CalculatorContent />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="calculator-icon-wrapper">
                <div className="mr--1">
                  <img src={require('../../assets/img/money-art.png')} alt="" />
                </div>
                <div>
                  <h1>Rp 500M+</h1>
                  <p>Total Aset</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="calculator-icon-wrapper">
                <div className="mr--1">
                  <img src={require('../../assets/img/client-art.png')} alt="" />
                </div>
                <div>
                  <h1>25.000+</h1>
                  <p>Nasabah Aktif</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="calculator-icon-wrapper">
                <div className="mr--1">
                  <img src={require('../../assets/img/check-art.png')} alt="" />
                </div>
                <div>
                  <h1>20+ tahun</h1>
                  <p>Lama berdiri</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-wrapper">
        <div className="container">
          <div className="row mb--1">
            <div className="col">
              <h1 className="title-big">Keunggulan Dana Tunai</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 col-6">
              <div className="excellence-wrapper">
                <img src={require('../../assets/img/keunggulan-1.png')} alt="" />
                <div className="excellence-content">
                  <p>Angsuran Hemat</p>
                  <p className="text--size-12">- Dari 200rb-an / bulan</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6">
              <div className="excellence-wrapper">
                <img src={require('../../assets/img/keunggulan-2.png')} alt="" />
                <div className="excellence-content">
                  <p>Pencairan Tinggi</p>
                  <p className="text--size-12">- Sampai dengan 200jt</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6">
              <div className="excellence-wrapper">
                <img src={require('../../assets/img/keunggulan-3.png')} alt="" />
                <div className="excellence-content">
                  <p>Tidak Ribet</p>
                  <p className="text--size-12">- Langsung melalui web tanpa app</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-wrapper section-gray">
        <div className="container">
          <div className="row mb--2">
            <div className="col">
              <h1 className="title-big text--center">Apa kata client CMD</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <Testimoni />
            </div>
          </div>
        </div>
      </section>
      <section className="section-wrapper section-review">
        <div className="section-review-art section-review-art--left">
          <img src={require('../../assets/img/review-art.png')} alt="" />
        </div>
        <div className="section-review-art section-review-art--right">
          <img src={require('../../assets/img/review-art.png')} alt="" />
        </div>
        <div className="container">
          <div className="row mb--2 justify-content-center">
            <div className="col-md-8">
              <div className="text--center">
                <h1 className="title-big text--center">Gabung menjadi agen CMD</h1>
                <p className="mb--2">
                  Dapatkan penghasilan jutaan rupiah di waktu luangmu dengan bergabung menjadi agen CMD
                </p>
                <Button text="Gabung Sekarang" onClick={() => window.open('https://forms.gle/aTjT5RCah5kAuaa37','_blank')} />
              </div>
            </div>
          </div>
        </div>
        <div className="review-slide-container">
          <Review />
        </div>
      </section>
      <section className="section-wrapper section-gray">
        <div className="container">
          <div className="row mb--2">
            <div className="col">
              <div className="text--center">
                <h1>Telah diliput oleh</h1>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-3 col-sm-6 col-6 col-media">
              <div className="media-img">
                <a href="https://www.cnbcindonesia.com/market/20200405103022-17-149845/kabar-baik-ini-46-leasing-yang-ringankan-cicilan-nasabahnya" target="_blank"><img src={require('../../assets/img/cnbc.png')} alt="" /></a>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6 col-media">
              <div className="media-img">
                <a href="https://finansial.bisnis.com/read/20220527/89/1537162/pembiayaan-naik-leasing-lokal-capella-multidana-raup-laba-rp1409-miliar" target="_blank"><img src={require('../../assets/img/bisnisCom.png')} alt="" /></a>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6 col-media">
              <div className="media-img">
                <a href="https://riaupos.jawapos.com/ekonomi-bisnis/04/09/2018/188695/pt-capella-multidana-berikan-pemahaman-fidusia.html" target="_blank"><img src={require('../../assets/img/riaupos.png')} alt="" /></a>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6 col-media">
              <div className="media-img">
                <a href="https://infobanknews.com/inilah-para-peraih-18th-infobank-multifinance-awards-2022/" target="_blank"><img src={require('../../assets/img/infoBank.png')} alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-wrapper section-car">
        <div className="container">
          <div className="row mb--2">
            <div className="col-md-6">
              <div className="mb--2">
                <h1 className="title-big">Temukan mobil &amp; motor bekas impian</h1>
                <p>Harga Jujur. Pembiayaan Cepat. Capella Certified</p>
              </div>
              <Button text="Lihat Lebih Banyak di oto.cmd" onClick={() => window.open('https://oto.cmd.co.id/')} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-6 col-6 col-carouse-car-1">
              <div className="car-carousel-vertical flipper">
                <div className={flip1 ? "car-carousel-wrapper flipper-card flipper-is-flipped" : "car-carousel-wrapper flipper-card"} onClick={() => window.open(`https://oto.cmd.co.id/${data1.CarType ? "mobil" : "motor"}/${data1.id}`, "_blank")}>
                  <img className="flipper-img" src={data1 && data1.images[0]} alt="" />
                  <div className={flip1 ? "car-carousel-text flipper-back" : "car-carousel-text flipper-front"} style={flip1 ? {right: "15px"} : {left: "15px"}}>
                    <p className="car-carousel-text--name"><strong>{data1 && (data1.CarType ? data1.CarType.brand_name : data1.BikeType.brand_name) + " " + data1.year + data1.id}</strong></p>
                    <p className="car-carousel-text--discount">{rupiah(data1 && data1.price)}</p>
                    <p className="car-carousel-text--price">{rupiah(data1 && data1.price)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6 col-carouse-car-2">
              <div className="car-carousel-vertical flipper">
                <div className={flip2 ? "car-carousel-wrapper flipper-card flipper-is-flipped" : "car-carousel-wrapper flipper-card"} onClick={() => window.open(`https://oto.cmd.co.id/${data2.CarType ? "mobil" : "motor"}/${data2.id}`, "_blank")}>
                  <img className="flipper-img" src={data2 && data2.images[0]} alt="" />
                  <div className={flip2 ? "car-carousel-text flipper-back" : "car-carousel-text flipper-front"} style={flip2 ? {right: "15px"} : {left: "15px"}}>
                    <p className="car-carousel-text--name"><strong>{data2 && (data2.CarType ? data2.CarType.brand_name : data2.BikeType.brand_name) + " " + data2.year }</strong></p>
                    <p className="car-carousel-text--discount">{rupiah(data2 && data2.price)}</p>
                    <p className="car-carousel-text--price">{rupiah(data2 && data2.price)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6 col-carouse-car-3">
              <div className="car-carousel-vertical flipper">
                <div className={flip3 ? "car-carousel-wrapper flipper-card flipper-is-flipped" : "car-carousel-wrapper flipper-card"} onClick={() => window.open(`https://oto.cmd.co.id/${data3.CarType ? "mobil" : "motor"}/${data3.id}`, "_blank")}>
                  <img className="flipper-img" src={data3 && data3.images[0]} alt="" />
                  <div className={flip3 ? "car-carousel-text flipper-back" : "car-carousel-text flipper-front"} style={flip3 ? {right: "15px"} : {left: "15px"}}>
                    <p className="car-carousel-text--name"><strong>{data3 && (data3.CarType ? data3.CarType.brand_name : data3.BikeType.brand_name) + " " + data3.year }</strong></p>
                    <p className="car-carousel-text--discount">{rupiah(data3 && data3.price)}</p>
                    <p className="car-carousel-text--price">{rupiah(data3 && data3.price)}</p>
                  </div>
                </div>
                <div className={flip4 ? "car-carousel-wrapper flipper-card flipper-is-flipped" : "car-carousel-wrapper flipper-card"} onClick={() => window.open(`https://oto.cmd.co.id/${data4.CarType ? "mobil" : "motor"}/${data4.id}`, "_blank")}>
                  <img className="flipper-img" src={data4 && data4.images[0]} alt="" />
                  <div className={flip4 ? "car-carousel-text flipper-back" : "car-carousel-text flipper-front"} style={flip4 ? {right: "15px"} : {left: "15px"}}>
                    <p className="car-carousel-text--name"><strong>{data4 && (data4.CarType ? data4.CarType.brand_name : data4.BikeType.brand_name) + " " + data4.year }</strong></p>
                    <p className="car-carousel-text--discount">{rupiah(data4 && data4.price)}</p>
                    <p className="car-carousel-text--price">{rupiah(data4 && data4.price)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6 col-carouse-car-3">
              <div className="car-carousel-vertical">
                <div className={flip5 ? "car-carousel-wrapper flipper-card flipper-is-flipped" : "car-carousel-wrapper flipper-card"} onClick={() => window.open(`https://oto.cmd.co.id/${data5.CarType ? "mobil" : "motor"}/${data5.id}`, "_blank")}>
                  <img className="flipper-img" src={data5 && data5.images[0]} alt="" />
                  <div className={flip5 ? "car-carousel-text flipper-back" : "car-carousel-text flipper-front"} style={flip5 ? {right: "15px"} : {left: "15px"}}>
                    <p className="car-carousel-text--name"><strong>{data5 && (data5.CarType ? data5.CarType.brand_name : data5.BikeType.brand_name) + " " + data5.year }</strong></p>
                    <p className="car-carousel-text--discount">{rupiah(data5 && data5.price)}</p>
                    <p className="car-carousel-text--price">{rupiah(data5 && data5.price)}</p>
                  </div>
                </div>
                <div className={flip6 ? "car-carousel-wrapper flipper-card flipper-is-flipped" : "car-carousel-wrapper flipper-card"} onClick={() => window.open(`https://oto.cmd.co.id/${data6.CarType ? "mobil" : "motor"}/${data6.id}`, "_blank")}>
                  <img className="flipper-img" src={data6 && data6.images[0]} alt="" />
                  <div className={flip6 ? "car-carousel-text flipper-back" : "car-carousel-text flipper-front"} style={flip6 ? {right: "15px"} : {left: "15px"}}>
                    <p className="car-carousel-text--name"><strong>{data6 && (data6.CarType ? data6.CarType.brand_name : data6.BikeType.brand_name) + " " + data6.year }</strong></p>
                    <p className="car-carousel-text--discount">{rupiah(data6 && data6.price)}</p>
                    <p className="car-carousel-text--price">{rupiah(data6 && data6.price)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MainFooter />
      <section className="section-wrapper section-footer-bottom">
        <div className="container">
          <div className="row mb--2">
            <div className="col">
              <h1>Suku Bunga &amp; Biaya</h1>
            </div>
          </div>
          <div className="row">
            <div className="col col-footer-bottom-menu">
              <div>
                <div className="footer-bottom-menu">
                  <span className="text--size-12"><strong>Pinjaman</strong></span>
                </div>
                <div>
                  <p>
                    Rp 3.000.000 - Rp 200.000.000
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-footer-bottom-menu">
              <div>
                <div className="footer-bottom-menu">
                  <span className="text--size-12"><strong>Biaya Admin</strong></span>
                </div>
                <div>
                  <p>
                    Rp 269.000 - Rp 2.050.000
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-footer-bottom-menu">
              <div>
                <div className="footer-bottom-menu">
                  <span className="text--size-12"><strong>Suku Bunga</strong></span>
                </div>
                <div>
                  <p>
                    1% - 3% / bulan
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-footer-bottom-menu">
              <div>
                <div className="footer-bottom-menu">
                  <span className="text--size-12"><strong>Lama Pinjaman</strong></span>
                </div>
                <div>
                  <p>
                    6 - 48 bulan
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-footer-bottom-menu">
              <div>
                <div className="footer-bottom-menu">
                  <span className="text--size-12"><strong>Biaya Keterlambatan</strong></span>
                </div>
                <div>
                  <p>
                    0.5% / hari
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home