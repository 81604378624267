import React from 'react';
import { Link, withRouter } from 'react-router-dom'

import { withContext } from '../context/withContext';

import './BigMenu.css';

const BigMenu = ({ logo, closeMenu, bigMenuArt, history, context }) => {
  const clickMenu = (to) => {
    document.querySelector('html').classList.remove('no-scroll')
    context.setBigMenuOpen(false)
    history.push(to)
  }

  return (
    <div className="big-menu">
      <div className="big-menu_header">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-4 col-6 col-sm-6">
              <div className="big-menu-logo logo">
                { logo }
              </div>
            </div>
            <div className="col-md-1 col-6 col-sm-6">
              { closeMenu }
            </div>
          </div>
        </div>
      </div>
      <div className="big-menu_body main-page-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-4 order-md-1 order-2 order-sm-2">
              <div className="feature-link-col">
                <p className="text--size-12"><strong>Tautan Lainnya</strong></p>
                <div className="text--size-24">
                  {/* <div>
                    <a>Tentang Kami</a>
                  </div> */}
                  <div>
                    <a href="" onClick={() => clickMenu('/karir')}>Karir</a><br />
                    <a href="" onClick={() => clickMenu('/refund-policy')}>Refund Policy</a><br />
                    <a href="" onClick={() => clickMenu('/term-and-conditions')}>Term & Conditions</a>
                  </div>
                </div>
              </div>
              <div className="feature-link-col">
                <p className="text--size-12"><strong>Telepon</strong></p>
                <div className="text--size-24">
                  <a href="tel:+62614575838">061 457 5838</a>
                </div>
              </div>
              <div className="feature-link-col">
                <p className="text--size-12"><strong>Email</strong></p>
                <div className="text--size-24">
                  <a href="mailto:contact@cmd.com">cs@cmd.co.id</a>
                </div>
              </div>
              <div className="feature-link-col">
                <p className="text--size-12"><strong>Alamat</strong></p>
                <div className="text--size-24">
                  <p>Jl. Nibung Raya No 144-148</p>
                  <p>Petisah Tengah, Medan Petisah</p>
                  <p>Medan, Sumatera Utara 20112</p>
                </div>
              </div>
            </div>
            <div className="col-md-8 order-md-2 order-1 order-sm-1">
              <div>
                <ul className="big-menu__main">
                  <li>
                    <p className="text--size-12"><strong>01</strong></p>
                    {/* WILL REPLACE WITH <LINK> */}
                    <a href="" onClick={() => clickMenu('/')}>Beranda</a>
                  </li>
                  <li>
                    <p className="text--size-12"><strong>02</strong></p>
                    {/* WILL REPLACE WITH <LINK> */}
                    <a href="" onClick={() => clickMenu('/pinjaman')}>Pinjaman</a>
                  </li>
                  <li>
                    <p className="text--size-12"><strong>03</strong></p>
                    {/* WILL REPLACE WITH <LINK> */}
                    <a href="" onClick={() => clickMenu('/pembayaran')}>Pembayaran</a>
                  </li>
                  <li>
                    <p className="text--size-12"><strong>04</strong></p>
                    {/* WILL REPLACE WITH <LINK> */}
                    <a href="https://oto.cmd.co.id">OTO</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="big-menu__art">
        { bigMenuArt }
      </div>
    </div>
  )
}

export default withContext(withRouter(BigMenu));
