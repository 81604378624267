import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import './Accordion.css'

const AccordionCareer = ({ location, job, children }) => {
  return (
    <div className="row align-items-center accordion-wrapper">
      <div className="col-2 accordion-career-location">
        <p className="mb--0">{ location }</p>
      </div>
      <div className="col-8 accordion-career-job">
        <h1 className="title-big mb--0">
          { job }
        </h1>
      </div>
      <div className="col-2">
        <div className="accordion-career-icon">
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        </div>
      </div>
    </div>
  )
}

export default AccordionCareer