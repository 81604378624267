import React from 'react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './Testimoni.css'

SwiperCore.use([Navigation, Pagination])

const Testimoni = () => {
  const renderStart = (total) => {
    const stars = []
    for (let i = 0;i < total;i++) {
      const star = (
        <span className="testimoni-star">
          <FontAwesomeIcon icon="star" />
        </span>
      )
      stars.push(star)
    }
    return stars
  }

  return (
    <div className="cmd-slide-container">
      <Swiper
        spaceBetween={0}
        navigation
        slidesPerView={1}>
          <SwiperSlide>
            <div className="testimoni-slide">
              <div className="mb--1 testimoni-img">
                <img src={require('../../assets/img/testimoni-1.png')} alt=""/>
              </div>
              <div className="mb--1 testimoni-name">
                <p>Dewi</p>
                <p className="testimoni-occupation">Karyawan Swasta</p>
              </div>
              <div className="mb--2">
                { renderStart(4) }
              </div>
              <div className="testimoni-content">
                <img className="left-testimoni-image" src={require('../../assets/img/opened-quote.png')} alt=""/>
                  <p>
                    Bunganya rendah di bandingkan tempat lain..syaratnya mudah..marketingnya ramah
                  </p>
                <img className="right-testimoni-image" src={require('../../assets/img/closed-quote.png')} alt=""/>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimoni-slide">
              <div className="mb--1 testimoni-img">
                <img src={require('../../assets/img/testimoni-2.png')} alt=""/>
              </div>
              <div className="mb--1 testimoni-name">
                <p>Iriandi</p>
                <p className="testimoni-occupation">Kepala Desa</p>
              </div>
              <div className="mb--2">
                { renderStart(5) }
              </div>
              <div className="testimoni-content">
                <p>
                  Angsurannya Ringan tapi prosesnya kalau bisa lebih mudah karena kami tanda tangan kontraknya dan foto unit di kantor, cukup jauh dari rumah kami di Kandis.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimoni-slide">
              <div className="mb--1 testimoni-img">
                <img src={require('../../assets/img/testimoni-3.png')} alt=""/>
              </div>
              <div className="mb--1 testimoni-name">
                <p>Netri Yenti</p>
                <p className="testimoni-occupation">Wiraswasta</p>
              </div>
              <div className="mb--2">
                { renderStart(5) }
              </div>
              <div className="testimoni-content">
                <p>
                  CMD Prosesnya MUDAH, TIDAK RIBET dan penjelasan dari CMO juga sangat baik. Mantap deh pokoknya
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimoni-slide">
              <div className="mb--1 testimoni-img">
                <img src={require('../../assets/img/testimoni-4.png')} alt=""/>
              </div>
              <div className="mb--1 testimoni-name">
                <p>Amizar</p>
                <p className="testimoni-occupation">Depot Air</p>
              </div>
              <div className="mb--2">
                { renderStart(5) }
              </div>
              <div className="testimoni-content">
                <p>
                  Berkat Capella multidana, usaha depot air minum saya jadi bisa nambah galon baru 100 biji lagi. Sehingga pelanggan jadi lebih banyak.
                </p>
              </div>
            </div>
          </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Testimoni