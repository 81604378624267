import React from 'react';
import { Header } from '@cmd/library';

import { withContext } from '../context/withContext';

const MainHeader = ({ context }) => {
  const openBigMenu = () => {
    document.querySelector('html').classList.add('no-scroll')
    context.setBigMenuOpen(true)
  }

  return (
    <Header
      logo={
        <div className="logo">
          <a href="/">
            <img src={require('../assets/img/cmd-logo.png')} alt=""/>
          </a>
        </div>
      }
      hamburger={
        <div className="hamburger-menu" onClick={openBigMenu}>
          <img src={require('../assets/img/hamburger.png')} alt=""/>
        </div>
      }
    />
  )
}

export default withContext(MainHeader)
