import React from "react";
import { Accordion } from "@cmd/library";

const Cimb = () => {
  return (
    <div className="paylease-accordion-container">
      <div className="mb--1">
        <Accordion title="ATM CIMB Niaga">
          <ol type="1">
            <li>Masukkan kartu ATM anda</li>
            <li>Pilih bahasa</li>
            <li>Masukkan PIN ATM anda</li>
            <li>Pilih menu "Transfer" dan lalu pilih “Other CIMB Niaga”</li>
            <li>Masukkan Nomor Virtual Account Anda 9349988556620621 (contoh) pada menu "Input New"</li>
            <li>Masukkan nominal yang harus dibayarkan</li>
            <li>Konfirmasi transaksi dan masukkan Password Transaksi</li>
            <li>Transaksi Anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Internet Banking CIMB Niaga">
          <ol type="1">
            <li>Buka situs <a href="https://www.octoclicks.co.id/login" target="_blank">https://www.octoclicks.co.id/login</a></li>
            <li>Masukkan User ID dan Password</li>
            <li>Pilih menu "Transfer" dan lalu pilih “Other CIMB Niaga”</li>
            <li>Masukkan Nomor Virtual Account Anda9349988556620621(contoh) pada menu "Input New"</li>
            <li>Masukkan nominal yang harus dibayarkan</li>
            <li>Konfirmasi transaksi dan masukkan Password Transaksi</li>
            <li>Transaksi Anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
      <div className="mb--1">
        <Accordion title="Mobile Banking CIMB Niaga">
          <ol type="1">
            <li>Akses Octo Mobile melalui handphone</li>
            <li>Masukkan User ID dan Password</li>
            <li>Pilih menu "Transfer" dan lalu pilih “Other CIMB Niaga”</li>
            <li>Masukkan Nomor Virtual Account Anda 9349988556620621(contoh) pada menu "Input New"</li>
            <li>Masukkan nominal yang harus dibayarkan</li>
            <li>Konfirmasi transaksi dan masukkan Password Transaksi</li>
            <li>Transaksi Anda telah selesai</li>
            <li>Setelah transaksi anda selesai, invoice ini akan diupdate secara otomatis. Proses ini mungkin memakan waktu hingga 5 menit</li>
          </ol>
        </Accordion>
      </div>
    </div>
  )
}

export default Cimb;
