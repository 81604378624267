import React from 'react'

import Sidebar from './Sidebar'
import PayLease from './PayLease'
import Footer from '../../components/Footer'

import './index.css';

const Paylease = () => {
  return (
    <div className="main-page">
      <div className="container-fluid">
        <div className="row justify-content-end">
          <div className="col-md-4 sidebar-col">
            <div className="sidebar-page-wrapper">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="main-page-wrapper">
                  <div className="main-page-container">
                    <PayLease />
                  </div>
                </div>
              </div>
            </div>
            <div className="main-footer-container">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Paylease