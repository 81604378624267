import { createContext } from 'react'

export const Context = createContext(
  {
    context: {
      isBigMenuOpen: false,
      setBigMenuOpen: () => {},
      showCalculator: false,
      setShowCalculator: () => {},
      loanSimulation: {
        guarantee: '',
        loanAmount: 3000000,
        installmentPerMonth: 0,
        installmentPeriod: 6
      },
      setLoanSimulation: () => {},
      payleaseWizardStep: 1,
      setPayleaseWizardStep: () => {}
    }
  }
)