import React from 'react';
import BigMenu from './BigMenu';

import { withContext } from '../context/withContext';

const links = {
  home: '/',
  career: '/karir',
  paylease: '/pembayaran',
  applyLoan: '/pinjaman',
  automotive: 'https://oto.cmd.co.id'
}

const MegaMenu = ({ context }) => {
  const closeBigMenu = () => {
    document.querySelector('html').classList.remove('no-scroll')
    context.setBigMenuOpen(false)
  }
  return context.isBigMenuOpen ? (
    <BigMenu
      logo={
        <img src={require('../assets/img/cmd-logo.png')} alt=""/>
      }
      closeMenu={
        <div className="hamburger-menu hamburger-menu-close" onClick={closeBigMenu}>
          <img src={require('../assets/img/cross.png')} alt=""/>
        </div>
      }
      bigMenuArt={
        <img src={require('../assets/img/coin.png')} alt=""/>
      }
      links={links}
    />
  ) : null
}

export default withContext(MegaMenu);
