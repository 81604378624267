import React, { useEffect, useState, useRef } from 'react'
import { Input, Button } from '@cmd/library'
import { useHistory } from 'react-router-dom'
import { rupiah } from '@cmd/library/lib/utils/function'
import InputRange from 'react-input-range'
import _ from 'lodash'

import { moneyToString } from '@cmd/library/lib/utils/function'

import { withContext } from '../context/withContext'

import { httpClient } from '../utils/httpClient'

const CalculatorContent = ({ context }) => {
  const history = useHistory();
  const [isCar, setCar] = useState(context.loanSimulation.guarantee == 'mobil' ? true : false);
  const [installmentAmount, setInstallmentAmount] = useState(0);


  useEffect(() => {
    const setInstallment = async() => {
      const {data} = await httpClient.post(`/application/calculator`, {
        amount: context.loanSimulation.loanAmount,
        tenor: context.loanSimulation.installmentPeriod,
        is_car: isCar,
      })
      setInstallmentAmount(data.installment);
    }

    setInstallment();
  }, []);

  const loanAmountSliderConfig = {
    maxValue: isCar ? 200000000 : 30000000,
    minValue: isCar ? 30000000 : 3000000 ,
    step: isCar ? 5000000 : 500000
  }

  const loanPeriodSliderConfig = {
    maxValue: isCar ? 48 : 36,
    minValue: isCar ? 12 : 6,
    step: isCar ? 12 : 6
  }

  const guaranteeOptions = {
    name: 'guarantee',
    items: [
      {
        id: 'motor',
        value: 'motor',
        text: 'Motor'
      },
      {
        id: 'mobil',
        value: 'mobil',
        text: 'Mobil'
      }
    ]
  }

  function onLoanAmountChange(value) {
    setLoanAmount(value)
    calculateAmount(value, context.loanSimulation.installmentPeriod, isCar)
  };

  function onInstallmentChange(value) {
    setInstallment(value)
    calculateAmount(context.loanSimulation.loanAmount, value, isCar)
  }

  function onRadioChange (e) {
    let amount;
    let installment;
    if (e.target.value === 'mobil') {
      setCar(true);
      amount = 30000000;
      installment = 12;
    } else {
      setCar(false);
      amount = 3000000;
      installment = 6;
    }
    context.setLoanSimulation({ guarantee: e.target.value, loanAmount: amount, installmentPeriod: installment })
    context.setLoanPayload({
      loan_amount: amount,
      tenor: installment,
      collateral_is_car: e.target.value === 'mobil'
    })
    calculateAmount(amount, installment, e.target.value === 'mobil')
  }

  function handleSave () {
    context.setLoanSimulation({ installmentPerMonth: installmentAmount })
    context.setShowCalculator(false)

    if (!window.location.href.includes("contact")) {
      history.push('/pinjaman/contact')
    }
  }

  function setLoanAmount (amount) {
    context.setLoanSimulation({ loanAmount: amount })
    context.setLoanPayload({ loan_amount: amount })
  }

  function setInstallment (installment) {
    context.setLoanSimulation({ installmentPeriod: installment })
    context.setLoanPayload({ tenor: Number(installment) })
  }

  const calculateAmount = useRef(_.debounce((amount, tenor, isCar) => {
    return httpClient.post(`/application/calculator`, {
      amount,
      tenor,
      is_car: isCar,
    })
    .then(({ data }) => {
      setInstallmentAmount(data.installment)
      context.setLoanSimulation({ installmentPerMonth: data.installment })
    })
    .catch(() => setInstallmentAmount(0))
  }, 500)).current

  return (
    <div>
      <div className="row mb--2">
        <div className="col">
          <p className="text--size-12"><strong>Total Pinjaman</strong></p>
          <div className="mt--1 mb--2">
            <div className="input-range-calculator">
              <InputRange
                maxValue={loanAmountSliderConfig.maxValue}
                minValue={loanAmountSliderConfig.minValue}
                step={loanAmountSliderConfig.step}
                formatLabel={value => moneyToString(value)}
                value={context.loanSimulation.loanAmount}
                onChange={onLoanAmountChange} />
            </div>
          </div>
          <div className="pt--1 input-range-text">
            <Input type="text" placeholder="" value={rupiah(context.loanSimulation.loanAmount)} disabled />
          </div>
        </div>
      </div>
      <div className="row mb--2">
        <div className="col">
          <div>
            <p className="text--size-12"><strong>Jenis Kendaraan untuk jaminan</strong></p>
            <div className="row">
            { guaranteeOptions.items.map((option) => {
              return (
                <div className="col-6" key={`radio-cal-${option.id}`}>
                  <div className="radio-wrapper">
                    <div className="radio-point">
                      <input type="radio" name={guaranteeOptions.name} id={option.id} value={option.value} onChange={onRadioChange} checked={context.loanSimulation.guarantee === option.value} />
                      <div className="radio-mask">
                        <div className="radio-mask-insider"></div>
                      </div>
                    </div>
                    <div className="radio-label">
                      <label htmlFor={option.id}>{ option.text }</label>
                    </div>
                  </div>
                </div>
              )
            }) }
        </div>
          </div>
        </div>
      </div>
      <div className="row mb--2">
        <div className="col">
          <p className="text--size-12"><strong>Lama Pinjaman</strong></p>
          <div className="mt--1 mb--2">
            <div className="input-range-calculator">
              <InputRange
                maxValue={loanPeriodSliderConfig.maxValue}
                minValue={loanPeriodSliderConfig.minValue}
                formatLabel={value => `${value} Bulan`}
                step={loanPeriodSliderConfig.step}
                value={context.loanSimulation.installmentPeriod}
                onChange={onInstallmentChange} />
            </div>
          </div>
          <div className="pt--1">
            <Input type="text" placeholder="" value={context.loanSimulation.installmentPeriod} disabled />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="box-gray">
            <div className="row align-items-center">
              <div className="col-md-8">
                <div>
                  <p className="text--weight-700 text--size-12">Estimasi Cicilan Per Bulan</p>
                  <p className="text--size-12">sudah termasuk bunga dan biaya admin</p>
                </div>
                <div>
                  <h2 className="text--color-blue">{ rupiah(installmentAmount) }</h2>
                </div>
              </div>
              <div className="col-md-4">
                <div className="btn-calc-save">
                  <Button text="Ajukan" onClick={handleSave} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withContext(CalculatorContent)