import React, { useState } from 'react'
import { Upload, Input, Button } from '@cmd/library'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useHistory } from 'react-router-dom'

import Select from '../../components/Form/Select'
import { withContext } from '../../context/withContext'
import { httpClient } from '../../utils/httpClient'

const Guarantee = ({ context }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const history = useHistory();

  // Default values
  const isCar = context.loanPayload.collateral_is_car;
  const d = new Date()
  const year = d.getFullYear()
  const carYears = [];
  for (let i = 0; i < 11; i++) carYears.unshift(year-i)
  const bikeYears = [];
  for (let i = 0; i < 7; i++) bikeYears.unshift(year-i)
  const unitYearOptions = isCar ? carYears : bikeYears;

  function validate () {
    if (context.loanPayload.collateral_year === '') return false
    if (context.loanPayload.collateral_front_image_url === '') return false
    if (context.loanPayload.collateral_rear_image_url === '') return false
    if (context.loanPayload.stnk_image_url === '') return false
    if (isCar && context.loanPayload.collateral_dashboard_image_url === '') return false

    return true
  }

  function handleUpload (file, id, name) {
    if (file) {
      setUploading(true)
      const formData = new FormData()

      formData.append('image_name', id)
      formData.append('image', file)
      formData.append('folder', context.folderName)

      return httpClient.post('/upload', formData).then(({ data }) => {
        context.setLoanPayload({ [name]: data.url })
        setUploading(false)
      })
    }
  }

  function removeEmpty (obj) {
     Object.entries(obj).forEach(
      ([key, val]) =>
        (val && typeof val === 'object' && removeEmpty(val)) ||
        ((val === null || val === '' || val === undefined) && delete obj[key]),
    )
    return obj;
  }

  function submitLoan () {
    const payload = removeEmpty(context.loanPayload)

    return httpClient.post('/application/walkInApplication', { ...payload })
  }

  return (
    <div>
      <div className="row mb--1">
        <div className="col">
          <Select
            placeholder="Tahun Unit Jaminan"
            value={context.loanPayload.collateral_year}
            options={unitYearOptions.map((val) => ({ value: val, label: val }))}
            onChange={(selectValue) => context.setLoanPayload({ collateral_year: `${selectValue}` })}
            icon={<FontAwesomeIcon icon="angle-down" />} />
        </div>
      </div>
      <div className="row mb--2">
        <div className="col-md-6 mb--1">
          <Upload
            onChange={(value) => handleUpload(value, 'collateral_front_image', 'collateral_front_image_url')}
            accept="image/*"
            placeholder={
              <div className="text--center">
                <img src={require('../../assets/img/camera-icon.svg')} alt="" className="mb--1"/>
                <p className="text--size-12 text--color-gold mb--0"><strong>Foto Jaminan Tampak Depan</strong></p>
              </div>
            }
          />
        </div>
        <div className="col-md-6 mb--1">
          <Upload
            onChange={(value) => handleUpload(value, 'collateral_rear_image', 'collateral_rear_image_url')}
            accept="image/*"
            placeholder={
              <div className="text--center">
                <img src={require('../../assets/img/camera-icon.svg')} alt="" className="mb--1"/>
                <p className="text--size-12 text--color-gold mb--0"><strong>Foto Jaminan Tampak Belakang</strong></p>
              </div>
            }
          />
        </div>
        {
          isCar && (
            <div className="col-md-6 mb--1">
              <Upload
                onChange={(value) => handleUpload(value, 'collateral_dashboard_image', 'collateral_dashboard_image_url')}
                accept="image/*"
                placeholder={
                  <div className="text--center">
                    <img src={require('../../assets/img/camera-icon.svg')} alt="" className="mb--1"/>
                    <p className="text--size-12 text--color-gold mb--0"><strong>Foto Jaminan Dashboard</strong></p>
                  </div>
                }
              />
            </div>
          )
        }
        <div className="col-md-6 mb--1">
          <Upload
            onChange={(value) => handleUpload(value, 'stnk_image', 'stnk_image_url')}
            accept="image/*"
            placeholder={
              <div className="text--center">
                <img src={require('../../assets/img/camera-icon.svg')} alt="" className="mb--1"/>
                <p className="text--size-12 text--color-gold mb--0"><strong>Foto STNK</strong></p>
              </div>
            }
          />
        </div>
      </div>
      <div className="fx">
        <div className="mr--2">
          <Button disabled={uploading || submitting || !validate()} text={submitting ? 'Mengirim...' : 'Kirim Pengajuan'} onClick={(e) => {
            setSubmitting(true)
            const valid = validate()
            if (!valid) {
              e.preventDefault()
              setErrorMessage('Mohon isi semua field yang tersedia')
            }

            submitLoan()
              .then(() => history.push('/pinjaman/loan-submitted'))
              .catch((e) => {
                if (e.response && e.response.status !== 500) {
                  setErrorMessage(e.response.data.message)
                } else {
                  setErrorMessage('Sedang ada kendala teknis. Mohon coba beberapa saat lagi.')
                }
              })
              .finally(() => {
                setSubmitting(false)
              })
          }} />
          <br/>
          { errorMessage && (<small style={{ color: 'red' }}>{errorMessage}</small>) }
        </div>
        <div>
          <Link to="/pinjaman/personal-information"><Button text="Batal" className="btn--accent" /></Link>
        </div>
      </div>
    </div>
  )
}

export default withContext(Guarantee)
